import React, { Fragment, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
// import gql from 'graphql-tag'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import{ filter, chain } from 'lodash'

import { useAppContext } from '../utils/contextUtil'
import logo from '../assets/img/fd_logo.png'
import Welcome from '../components/misc/Welcome'

import '../styles/Login.css'

export default function Login() {
	const { isAuthenticated, setIsAuthenticated } = useAppContext()
	const [email, setEmail] = useState('')
	const [ambassadorId, setAmbassadorId] = useState(null)
	const [token, setToken] = useState(null)
	const [ambassador, setAmbassador] = useState(null)
	const [gigs, setGigs] = useState(null)
	const [buttonText, setButtonText] = useState('Login')
	const [selectedGigId, setSelectedGigId] = useState(null)
	const [gigStarted, setGigStarted] = useState(false)
	const formRef = useRef(null)

	const now = Date.now()

	const upcomingPhoneGigs = gigs &&
		filter(gigs.results, gig =>
			gig.execution_type === 'PHONE' &&
			!gig.field_day_operation_details.finished_gig &&
			now - 21600000 < Number(gig.due_date_millis) && // gigs from 6 hours ago up until 30 days from now
			now + 2592000000 > Number(gig.due_date_millis)
		)

	// const upcomingPhoneGigs = gigs &&
	// 	filter(gigs.results, gig =>
	// 		gig.execution_type === 'PHONE' &&
	// 		!gig.field_day_operation_details.finished_gig
	// 	)

	const [getAmbassadorId, { loading: loadingAmbassadorId, data: ambassadorIdData }] = useLazyQuery(GET_AMBASSADOR_ID, {
		onCompleted(data) {
			setAmbassadorId(data.getAmbassadorId.ambassador_id)
			setToken(data.getAmbassadorId.token)
			setIsAuthenticated(true)
			setEmail('')

			localStorage.setItem('ambassadorId', ambassadorIdData.getAmbassadorId.ambassador_id)

			getAmbassador({
				variables: {
					ambassadorId: data.getAmbassadorId.ambassador_id
				}
			})

			getGigs({
				variables: {
					ambassadorId: data.getAmbassadorId.ambassador_id,
					feedType: 'SCHEDULED',
					pageFrom: 0,
					pageSize: 1000
				}
			})
		}
	})

	const [getAmbassador, { loading: loadingAmbassador, data: ambassadorData }] = useLazyQuery(GET_AMBASSADOR, {
		onCompleted(data) {
			setAmbassador(data.getAmbassador)
		}
	})

	const [getGigs, { loading: loadingGigs, data: gigsData }] = useLazyQuery(GET_AMBASSADOR_GIGS, {
		onCompleted(data) {
			setGigs(data.getGigs)
		}
	})

	const [startGig] = useMutation(START_GIG, {
		onCompleted(data) {
			setGigStarted(true)
		}
	})

	// if(loadingAmbassadorId || loadingAmbassador) {
	// 	setButtonText('Logging in..')
	// }

	if(gigStarted) {
		localStorage.setItem('gigId', selectedGigId)

		return (
			<Redirect
				// push
				to={{
					pathname: '/phoneGig',
					search: `id=${selectedGigId}`,
					// TODO; figure out why this isn't passing through
					// state: { gigId: upcomingPhoneGigs[0].id }
				}}
			/>
		)
	}

	const handleLogin = (e) => {
		e.preventDefault()
		formRef.current.reset()

		getAmbassadorId({
			variables: { email }
		})
	}

	const handleGigClick = (gig) => {
		// at the next upcoming 6pm
		// const cronEndTime = '0 0 18 * * *'

		// at Friday 7pm
		// const cronEndTime = '0 19 * * FRI'

		setSelectedGigId(gig.id)

		if(gig.field_day_operation_details && gig.field_day_operation_details.started_gig) {
			setGigStarted(true)
		} else {
			startGig({
				variables: { id: gig.id }
			})
		}

		// axios.post(`http://localhost:5000/cronJobs/endGig?gigId=${gig.id}&shceduledEndTime=${cronEndTime}&timeZone=${gig.time_zone}`)
		// axios.post(`https://prod.ambassadors.gql.fieldday.app/cronJobs/endGig?gigId=${gig.id}&shceduledEndTime=${cronEndTime}&timeZone=${gig.time_zone}`)
	}

	return (
		<Fragment>
			{isAuthenticated && ambassador && upcomingPhoneGigs
				? <Welcome ambassador={ambassador} gigs={upcomingPhoneGigs} onGigClick={handleGigClick} />
				: <div className='login-container'>
						<Row className='flex-column text-center mb-5'>
							<img src={logo} alt='' />
						</Row>
						<Row className='login-form mt-5'>
							<Form className='w-100 d-flex flex-column' onSubmit={handleLogin} ref={formRef}>
								<Form.Group className='mb-5'>
									<Form.Control
										required
										type='email'
										placeholder='Email'
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>
									<Form.Text className='text-muted pl-4'>
										Same email as Field Day app
									</Form.Text>
								</Form.Group>
								<Button type='submit' variant='info' className='m-auto'>
									{buttonText}
								</Button>
								<div className='text-center mt-3'>
									Forgot password? <span className='fd-green pointer'>Click here</span>
								</div>
							</Form>
						</Row>
					</div>
			}
		</Fragment>
	)
}

const GET_AMBASSADOR_ID = gql`
	query GetAmbassadorId($email: String!) {
		getAmbassadorId(email: $email) {
			ambassador_id
			token
		}
	}
`

const GET_AMBASSADOR = gql`
	query GetAmbassador($ambassadorId: ID!) {
		getAmbassador(ambassadorId: $ambassadorId) {
			id
			first_name
			last_name
			profile_photos {
				photo_url
				default_photo
			}
		}
	}
`

const GET_AMBASSADOR_GIGS = gql`
	query GetGigs($ambassadorId: ID, $feedType: String, $pageFrom: Int, $pageSize: Int) {
		getGigs(ambassadorId: $ambassadorId, feedType: $feedType, pageFrom: $pageFrom, pageSize:$pageSize) {
			total
			results {
				id
				execution_type
				due_date_millis
				title
				time_zone
				time_zone_human
				field_day_operation_details {
					started_gig
					finished_gig
				}
			}
		}
	}
`

const START_GIG = gql`
	mutation StartGig($id: ID!) {
		startGig(id: $id)
	}
`
