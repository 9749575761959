import React, { useEffect, useState } from 'react'

import { AppContext } from './utils/contextUtil'
import Routes from './Routes'
import Header from './components/shared/Header'

import './App.css'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const ambassadorId = localStorage.getItem('ambassadorId')

  useEffect(() => {
    if(ambassadorId) {
      setIsAuthenticated(true)
    }
  })

  return (
    <div>
      <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
        <Routes />
      </AppContext.Provider>
    </div>
  )
}

export default App
