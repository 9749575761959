import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import callIcon from '../../assets/img/call_icon.png'
import measureDistance  from '../../utils/measureDistance'

export default function TargetTable({ contacts, onCall, gigLocation }) {
	return (
		<div className='contacts-table mt-3'>
			<Row noGutters className='contacts-table-header'>
				<Col md={3} className='pt-2 pb-2'>Business name</Col>
				<Col md={3} className='pt-2 pb-2'>Street</Col>
				<Col md={2} className='pt-2 pb-2'>City</Col>
				<Col md={2} className='pt-2 pb-2'>Phone</Col>
				<Col md={2} className='pt-2 pb-2'>Distance to store</Col>
			</Row>
			<div className='contacts-table-body'>
				{contacts && !contacts.length ? <div className='text-center font-italic font-weight-bold'>You have finished your list</div> : null}
				{contacts && contacts.map((contact, i) => (
					<Row noGutters key={contact.id} className='contacts-table-body-row'>
						<Col md={3} className='contacts-table-body-cell pl-2'><b>{i + 1}. {contact.company_name}</b></Col>
						<Col md={3} className='contacts-table-body-cell'>{contact.company_address}</Col>
						<Col md={2} className='contacts-table-body-cell'>{contact.city}</Col>
						<Col md={2} className='contacts-table-body-cell'>{contact.phone_1}</Col>
						<Col md={1} className='contacts-table-body-cell d-flex'>
							{contact.coordinates && gigLocation &&
								measureDistance(
									{ lng: Number(gigLocation[0]), lat: Number(gigLocation[1]) },
									{ lng: Number(contact.coordinates[0]), lat: Number(contact.coordinates[1]) }
							)} miles
						</Col>
						<Col className='contacts-table-body-cell'>
							<OverlayTrigger
								placement='top'
								overlay={(props) => <Tooltip {...props}>Click to call this business</Tooltip>}
							>
								<img
									alt=''
									className='pointer'
									src={callIcon}
									onClick={() => onCall(contact.phone_1, contact.id)}
									style={{
										maxHeight: '25px',
										maxWidth: '25px',
										marginLeft: '20px'
									}}
								/>
							</OverlayTrigger>
						</Col>
					</Row>
				))}
			</div>
		</div>
	)
}
