import React, { Fragment, useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Dropdown } from 'semantic-ui-react'
import { map, reduce, debounce } from 'lodash'

export default function TargetForm({ gigId, brandId, campaignId, calledTarget, calledTargetId, leadActionId, onShowRateModal, onScheduleFollowUpEmail, notesExist, setNotesExist, onEndCallAction }) {
	const [contactSaved, setContactSaved] = useState(false)
	const [leadActionSaved, setLeadActionSaved] = useState(false)
	const [targetUpdated, setTargetUpdated] = useState(false)
	const [inputSaved, setInputSaved] = useState(false)

	const [formState, setFormState] = useState({
		company_name: calledTarget.company_name,
		company_address: calledTarget.company_address,
		city: calledTarget.city,
		state: calledTarget.state,
		postal_code: calledTarget.postal_code,
		phone_1: calledTarget.phone_1,
		person_reached_name: '',
		employee_size: calledTarget.employee_size,
		frequency_of_need: calledTarget.frequency_of_need,
		next_steps: calledTarget.next_steps,
		contact_name: calledTarget.contact_name,
		contact_email: calledTarget.contact_email,
		contact_title: calledTarget.contact_title,
		phone_3: calledTarget.phone_3,
		questions: calledTarget.questions,
	})

	const [tempFormState, setTempFormState] = useState({
		company_name: calledTarget.company_name,
		company_address: calledTarget.company_address,
		city: calledTarget.city,
		state: calledTarget.state,
		postal_code: calledTarget.postal_code,
		phone_1: calledTarget.phone_1,
		person_reached_name: '',
		employee_size: calledTarget.employee_size,
		frequency_of_need: calledTarget.frequency_of_need,
		next_steps: calledTarget.next_steps,
		contact_name: calledTarget.contact_name,
		contact_email: calledTarget.contact_email,
		contact_title: calledTarget.contact_title,
		phone_3: calledTarget.phone_3,
		questions: calledTarget.questions,
	})

	const [editContact] = useMutation(EDIT_CONTACT, {
		// update(cache, result) {},
		onCompleted(data) {
			setContactSaved(true)
		}
	})

	const [editLeadAction] = useMutation(EDIT_LEAD_ACTION, {
		onCompleted(data) {
			setLeadActionSaved(true)
			setNotesExist(false)
		}
	})

	const [autoSaveContactInfo] = useMutation(EDIT_CONTACT, {
		// update(cache, result) {},
		onCompleted(data) {
			setInputSaved(true)
		}
	})

	const [autoSaveLeadActionInfo] = useMutation(EDIT_LEAD_ACTION, {
		onCompleted(data) {
			setInputSaved(true)
		}
	})

	const [updateTarget] = useMutation(UPDATE_TARGET, {
		onCompleted(data) {
			setTargetUpdated(true)
		}
	})

	useEffect(() => {
		if(contactSaved && leadActionSaved) {
			onShowRateModal()
		}

		if(targetUpdated && !contactSaved && !leadActionSaved) {
			onEndCallAction()
		}

	}, [contactSaved, leadActionSaved, targetUpdated])

	const handleInputChange = (e) => {
		const { name, value } = e.target

		setFormState({ ...formState, [name]: value })
	}

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return regex.test(email)
	}

	const validateForm = () => {
		if(formState.contact_email && !validateEmail(formState.contact_email.trim())) {
			alert('Email address is not in proper format')
			return false
		}

		if(formState.contact_email && !formState.contact_name) {
			alert('Contact Name is required')
			return false
		}

		if(!notesExist) {
			alert('Notes are required')
			return false
		}

		return true
	}

	// TODO; make sure not to change the field on the original contact object if no info is provided here
	const handleSaveInfo = () => {
		if(!validateForm()) {
			return
		}

		if(formState.contact_email) {
			onScheduleFollowUpEmail(formState.person_reached_name, formState.contact_email.trim())
		}

		const contactInfo = {
			id: calledTarget.id,
			contact_name: formState.contact_name,
			contact_email: formState.contact_email ? formState.contact_email.trim() : '',
			employee_size: formState.employee_size,
			frequency_of_need: formState.frequency_of_need,
			next_steps: formState.next_steps,
			phone_3: formState.fax,
			contact_title: formState.contact_title,
		}

		const leadActionInfo = {
			person_reached_name: formState.person_reached_name,
		}

		editContact({
			variables: {
				input: {
					...contactInfo
				}
			}
		})

		editLeadAction({
			variables: {
				id: leadActionId,
				leadAction: {
					...leadActionInfo
				}
			}
		})

		updateTarget({
			variables: {
				gigId,
				targetId: calledTargetId,
				target: {
					lead_action_id: leadActionId,
					was_called: true,
					picked_up: true,
					phone_exception_reason: 'SUCCESSFUL',
				}
			}
		})
	}

	// TODO; find a better way to autosave input fields
	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.person_reached_name !== formState.person_reached_name) {
				setInputSaved(false)

				autoSaveLeadActionInfo({
					variables: {
						id: leadActionId,
						leadAction: {
							person_reached_name: formState.person_reached_name
						}
					}
				})

				setTempFormState({ ...tempFormState, person_reached_name: formState.person_reached_name })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.person_reached_name])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.contact_email !== formState.contact_email) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							contact_email: formState.contact_email
						}
					}
				})

				setTempFormState({ ...tempFormState, contact_email: formState.contact_email })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.contact_email])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.contact_name !== formState.contact_name) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							contact_name: formState.contact_name
						}
					}
				})

				setTempFormState({ ...tempFormState, contact_name: formState.contact_name })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.contact_name])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.contact_title !== formState.contact_title) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							contact_title: formState.contact_title
						}
					}
				})

				setTempFormState({ ...tempFormState, contact_title: formState.contact_title })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.contact_title])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.employee_size !== formState.employee_size) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							employee_size: formState.employee_size
						}
					}
				})

				setTempFormState({ ...tempFormState, employee_size: formState.employee_size })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.employee_size])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.frequency_of_need !== formState.frequency_of_need) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							frequency_of_need: formState.frequency_of_need
						}
					}
				})

				setTempFormState({ ...tempFormState, frequency_of_need: formState.frequency_of_need })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.frequency_of_need])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.next_steps !== formState.next_steps) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							next_steps: formState.next_steps
						}
					}
				})

				setTempFormState({ ...tempFormState, next_steps: formState.next_steps })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.next_steps])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.phone_3 !== formState.phone_3) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							phone_3: formState.phone_3
						}
					}
				})

				setTempFormState({ ...tempFormState, phone_3: formState.phone_3 })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.phone_3])

	useEffect(() => {
		const timer  = setTimeout(() => {
			if(tempFormState.questions !== formState.questions) {
				setInputSaved(false)

				autoSaveContactInfo({
					variables: {
						input: {
							id: calledTarget.id,
							questions: formState.questions
						}
					}
				})

				setTempFormState({ ...tempFormState, questions: formState.questions })
			}
		}, 1000)

		return () => clearTimeout(timer)
	}, [formState.questions])

	const handleEndCallOptions = (reason) => {
		if(reason === 'CALL_BACK') {
			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						lead_action_id: leadActionId,
						was_called: true,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'INVALID_NUMBER') {
			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						was_called: true,
						lead_action_id: leadActionId,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'ENDED_BY_AMBASSADOR') {
			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						was_called: true,
						lead_action_id: leadActionId,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'VOICEMAIL') {
			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						was_called: true,
						lead_action_id: leadActionId,
						phone_exception_reason: reason
					}
				}
			})
		}
	}

	return (
		<Fragment>
			<div className='target-form-header mb-2'>
				{calledTarget.company_name}
				{inputSaved && <em style={{ fontSize: '11px', marginLeft: '1px' }}>(saved)</em>}
			</div>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#d7f0f2' }}>
							<span className='pl-2'>Business Name</span>
							<Form.Control
								readOnly={true}
								name='company_name'
								value={formState.company_name || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#d7f0f2' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Person Reached</span>
							<Form.Control
								name='person_reached_name'
								autoComplete='off'
								value={formState.person_reached_name || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#d7f0f2' }}>
							<span className='pl-2'>Address</span>
							<Form.Control
								readOnly={true}
								name='company_address'
								value={formState.company_address || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#d7f0f2' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Contact Name</span>
							<Form.Control
								name='contact_name'
								autoComplete='off'
								value={formState.contact_name || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#d7f0f2' }}>
							<span className='pl-2'>City</span>
							<Form.Control
								readOnly={true}
								name='city'
								value={formState.city || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#d7f0f2' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Contact Title</span>
							<Form.Control
								name='contact_title'
								autoComplete='off'
								value={formState.contact_title || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#d7f0f2' }}>
							<span className='pl-2'>State</span>
							<Form.Control
								readOnly={true}
								name='state'
								value={formState.state || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#d7f0f2' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Contact Email</span>
							<Form.Control
								autoComplete='off'
								name='contact_email'
								value={formState.contact_email || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#d7f0f2' }}>
							<span className='pl-2'>Zip</span>
							<Form.Control
								readOnly={true}
								name='postal_code'
								value={formState.postal_code || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#d7f0f2' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Number of Employees</span>
							<Form.Control
								name='employee_size'
								autoComplete='off'
								type='number'
								value={formState.employee_size || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#d7f0f2' }}>
							<span className='pl-2'>Phone</span>
							<Form.Control
								readOnly={true}
								name='phone_1'
								value={formState.phone_1 || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#d7f0f2' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Frequency of Need</span>
							<Form.Control
								as='textarea'
								rows={1}
								name='frequency_of_need'
								value={formState.frequency_of_need || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row noGutters>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Fax</span>
							<Form.Control
								name='phone_3'
								autoComplete='off'
								value={formState.phone_3 || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
					<Col className='mr-1 mb-1'>
						<div className='input-box' style={{ backgroundColor: '#f0f0f0' }}>
							<span className='pl-2'>Next Steps</span>
							<Form.Control
								as='textarea'
								rows={1}
								name='next_steps'
								value={formState.next_steps || ''}
								onChange={handleInputChange}
								style={{ backgroundColor: '#f0f0f0' }}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
					</Col>
				</Row>
				{(
					true // adding this for all brands
					|| brandId === '252'
					|| brandId === '187'
					|| brandId === '239'
					|| brandId === '269'
					|| brandId === '57'
					|| brandId === '290'
					|| brandId === '286'
					|| brandId === '285'
					) &&
					<Row>
						<Col>
							<TagsDropdown
								brandId={brandId}
								campaignId={campaignId}
								value={formState.questions ? formState.questions.map(q => q.question) : []}
								onChange={(name, value) => {
									setFormState({
										...formState,
										[name]: value.map(q => ({ question: q, answer: 'true' }))
									})}}
							/>
						</Col>
					</Row>
				}
				<Row>
					<Col>
						<Button
							variant='outline-info'
							className='w-100 font-weight-bold mt-3'
							onClick={() => handleEndCallOptions('CALL_BACK')}
						>
							I have to call back
						</Button>
					</Col>
					<Col>
						<Button
							variant='outline-info'
							className='w-100 font-weight-bold mt-3'
							onClick={() => handleEndCallOptions('INVALID_NUMBER')}
						>
							Invalid Number
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button
							variant='outline-info'
							className='w-100 font-weight-bold mt-3'
							onClick={() => handleEndCallOptions('VOICEMAIL')}
						>
							Voicemail
						</Button>
					</Col>
					<Col>
						<Button
							variant='outline-info'
							className='w-100 font-weight-bold mt-3'
							onClick={() => handleEndCallOptions('ENDED_BY_AMBASSADOR')}
						>
							I ended the call
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button
							variant='info'
							className='w-100 font-weight-bold mt-3'
							onClick={handleSaveInfo}
						>
							Done with this business
						</Button>
					</Col>
				</Row>
		</Fragment>
	)
}

function TagsDropdown({ brandId, value, onChange, campaignId }) {
	let options = []

	if(brandId === '187') {
		options = [
		  { key: 'SAMPLING', text: 'Sampling', value: 'SAMPLING' },
		  { key: 'CATERING', text: 'Catering', value: 'CATERING' },
		]
	} else {
		options = [
		  { key: 'SAMPLING', text: 'Sampling', value: 'SAMPLING' },
		]
	}

	// if(brandId === '252' && campaignId === 15341) {
	// 	options = [
	// 	  { key: 'SAMPLING', text: 'Sampling', value: 'SAMPLING' },
	// 	]
	// } else if(brandId === '239' && campaignId === 15140) {
	// 	options = [
	// 	  { key: 'SAMPLING', text: 'Sampling', value: 'SAMPLING' },
	// 	  { key: 'CATERING', text: 'Catering', value: 'CATERING' },
	// 	]
	// } else if(brandId === '187' || (brandId === '239' && campaignId === 15064)) {
	// 	options = [
	// 	  { key: 'CATERING', text: 'Catering', value: 'CATERING' },
	// 	] 
	// } else if(brandId === '269' || brandId === '57' || brandId === '290' || brandId === '286' || brandId === '285') {
	// 	options = [
	// 	  { key: 'SAMPLING', text: 'Sampling', value: 'SAMPLING' },
	// 	]
	// } else {
	// 	options = [
	// 	  { key: 'SAMPLING', text: 'Sampling', value: 'SAMPLING' },
	// 	  { key: 'CATERING', text: 'Catering', value: 'CATERING' },
	// 	]
	// }

	return (
		<Dropdown
			fluid
			multiple
			selection
			placeholder='Tags'
			options={options}
			style={{ fontWeight: '600', color: '#18a2b8' }}
			name='questions'
			value={value}
			onChange={(e, { name, value }) => onChange(name, value)}
		/>
	)
}

const EDIT_CONTACT = gql`
	mutation EditContact($input: EditContactInputs) {
		editContact(input: $input) {
			id
		}
	}
`

const EDIT_LEAD_ACTION = gql`
	mutation EditLeadAction($id: ID!,$ leadAction: CreateLeadActionInputs) {
		editLeadAction(id: $id, leadAction: $leadAction) {
			id
		}
	}
`

const UPDATE_TARGET = gql`
	mutation EditTarget($gigId: ID!, $targetId: ID!, $target: TargetInputs) {
		editTarget(gigId: $gigId, targetId: $targetId, target: $target) {
			target_id
			was_called
			start_time
			end_time
			duration
			picked_up
			left_voicemail
			contact_id
			lead_action_id
			phone_exception_reason
		}
	}
`
