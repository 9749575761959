import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

function DTMFTone({ device, onClose, showModal }) {
	const btnStyle = {
		width: '90px',
		height: '90px',
		borderRadius: '100%'
	}

	const sendDigit = (digit) => {
		device.activeConnection().sendDigits(digit)
	}

	return (
		<Modal
			centered
			show={showModal}
			onHide={onClose}
		>
			<Modal.Body>
			  <div className="keys">
			    <Col className='d-flex justify-content-around mb-3'>
		      	<Button variant='info' style={btnStyle} onClick={() => sendDigit('1')}>1
		      		<br />
		      	</Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('2')}>2
			      	<br />
			        <span>A B C</span>
			      </Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('3')}>3
			      	<br />
			        <span>D E F</span>
			      </Button>
			    </Col>
			    <Col className='d-flex justify-content-around mb-3'>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('4')}>4
			      	<br />
			        <span>G H I</span>
			      </Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('5')}>5
			      	<br />
			        <span>J K L</span>
			      </Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('6')}>6
			      	<br />
			        <span>M N O</span>
			      </Button>
			    </Col>
			    <Col className='d-flex justify-content-around mb-3'>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('7')}>7
			      	<br />
			        <span>P Q R S</span>
			      </Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('8')}>8
			      	<br />
			        <span>T U V</span>
			      </Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('9')}>9
			      	<br />
			        <span>W X Y Z</span>
			      </Button>
			    </Col>
			    <Col className='d-flex justify-content-around mb-3'>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('*')}>*</Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('0')}>0</Button>
			      <Button variant='info' style={btnStyle} onClick={() => sendDigit('#')}>#</Button>
			    </Col>
			  </div>
			</Modal.Body>
		</Modal>
	)
}

export default DTMFTone
