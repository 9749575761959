import React from 'react'
import { compose, withProps, withStateHandlers, lifecycle } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'

const googleApiKey = 'AIzaSyBzt4U31ue3BGvUsNj0v4MSKjm87sQLSzc'

const TargetMap = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)((props) => {
	const markers = []
	const bounds = new window.google.maps.LatLngBounds()

	props.contacts.results.forEach((contact, i) => {
		if(contact.coordinates) {
			bounds.extend({ lng: Number(contact.coordinates[0]), lat: Number(contact.coordinates[1]) })

			markers.push(
				<Marker
					key={i}
					label={String(i + 1)}
					position={{ lng: Number(contact.coordinates[0]), lat: Number(contact.coordinates[1]) }}
				/>
			)
		}
	})

	// adding store
	bounds.extend({ lng: Number(props.gig.loc_point[0]), lat: Number(props.gig.loc_point[1]) })

	const storeIcon = {
		path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    scale: 1,
    fillColor: "#0699a8",
    fillOpacity: 1,
    strokeWeight: 1
	}

	markers.push(
		<Marker
			key={123123123}
			icon={storeIcon}
			position={{ lng: Number(props.gig.loc_point[0]), lat: Number(props.gig.loc_point[1]) }}
		/>
	)

	return (
		<GoogleMap
			ref={map => map && map.fitBounds(bounds)}
			// defaultZoom={15}
			// defaultCenter={{ lng: Number(props.gig.loc_point[0]), lat: Number(props.gig.loc_point[1]) }}
		>
		{markers}
		</GoogleMap>
	)
})

export default TargetMap
