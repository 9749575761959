import React, { Fragment, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Device } from 'twilio-client'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { filter, includes, reduce, some, sortBy, each } from 'lodash'
import axios from 'axios'
import moment from 'moment-timezone'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import '../styles/PhoneGig.css'
import StatusBar from '../components/phoneGig/StatusBar'
import CallBar from '../components/phoneGig/CallBar'
import WhatToSay from '../components/phoneGig/WhatToSay'
import TargetMap from '../components/phoneGig/TargetMap'
import TargetTable from '../components/phoneGig/TargetTable'
import DTMFTone from '../components/phoneGig/DTMFTone'
import Timer from '../components/phoneGig/Timer'
import DistanceMap from '../components/phoneGig/DistanceMap'
import Notes from '../components/phoneGig/Notes'
import TargetForm from '../components/phoneGig/TargetForm'
import EndCallModal from '../components/phoneGig/EndCallModal'
import RateModal from '../components/phoneGig/RateModal'
import IdleModal from '../components/phoneGig/IdleModal'
import useIdle from '../utils/hooks/useIdle'

export default function PhoneGig() {
	const ambassadorId = localStorage.getItem('ambassadorId')
	const urlParams = new URLSearchParams(window.location.search)
	const gigId = urlParams.get('id')
	const [onPhone, setOnPhone] = useState(false)
	const [log, setLog] = useState('Connecting to twilio')
	const [showKeypad, setShowKeypad] = useState(false)
	const [muted, setMuted] = useState(false)
	const [calledTarget, setCalledTarget] = useState(null)
	const [calledTargetLeadActionId, setCalledTargetLeadActionId] = useState(null)
	const [calledTargetLeadAction, setCalledTargetLeadAction] = useState(null)
	const [showEndCallModal, setShowEndCallModal] = useState(false)
	const [showRatelModal, setShowRatelModal] = useState(false)
	const [numberToCall, setNumberToCall] = useState(null)
	const [contactIdToCall, setContactIdToCall] = useState(null)
	const [notesExist, setNotesExist] = useState(false)

	const isIdle = useIdle({
		timeToIdle: 300000,
		autoInit: true,
		inactivityEvents: []
	})

	const { loading: loadingGig, error: gigError, data: gigData, refetch: refetchGig } = useQuery(GET_GIG, {
		variables: {
			gigId
		},
		// update(cache, result) {},
		onCompleted(data) {
			getBrand({
				variables: {
					brandId: data.getGig.brand_id
				}
			})
		}
	})

	const { loading: loadingContacts, error: contactsError, data: contactsData, refetch: refetchContacts } = useQuery(GET_CONTACTS, {
		variables: {
			gigId
		}
	})

	const [getBrand, { loading: loadingBrand, error: brandError, data: brandData }] = useLazyQuery(GET_BRAND)

	const [getToken, { loading: loadingToken, data: tokenData }] = useLazyQuery(GET_TWILIO_TOKEN, {
		onCompleted(data) {
			Device.setup(data.getToken, {
				appName: 'BrowserDialer'
			})
		}
	})

	const [createLeadAction, { data }] = useMutation(CREATE_LEAD_ACTION, {
		onCompleted(data) {
			console.log('Lead Action Created: ', data)
			setCalledTargetLeadActionId(data.createLeadAction.id)
			handleCall(data.createLeadAction.id)
		}
	})

	const [getLeadAction, { loading: loadingLeadAction, data: leadActionData }] = useLazyQuery(GET_LEAD_ACTION, {
		onCompleted(data) {
			setCalledTargetLeadAction(data.getLeadAction)
		}
	})

	const [updateTarget] = useMutation(UPDATE_TARGET, {
		onCompleted(data) {
			console.log('target updated: ', data)
		}
	})

	const [editContact] = useMutation(EDIT_CONTACT, {
		onCompleted(data) {
			// setCalledTargetLeadActionId(null)
			// setCalledTarget(null)
			// setCalledTargetLeadAction(null)
			// setShowRatelModal(false)
			// // handleScheduleFollowUpEmail()
			// refetchGig()
			endCallAction()
		}
	})

	const endCallAction = () => {
		setCalledTargetLeadActionId(null)
		setCalledTarget(null)
		setCalledTargetLeadAction(null)
		setShowRatelModal(false)
		// handleScheduleFollowUpEmail()
		refetchGig()
		refetchContacts()
	}

	useEffect(() => {
		if(!ambassadorId) return

		getToken()

		// Configure event handlers for Twilio Device

		Device.on('ready', () => {
			setLog('DEVICE ready: ')
		})

		// This is triggered when a connection is opened, whether initiated using .connect() or via an accepted incoming connection.
		Device.on('connect', (connection) => {
			// console.log('DEVICE connect: ', connection)
			console.log('DEVICE connect, status: ', connection.status())
		})

		// Fired any time a Connection is closed.
		Device.on('disconnect', (connection) => {
    	console.log('DEVICE disconnect: ', connection)
    	setLog('Call ended')
			setOnPhone(false)
		})

		// This is triggered when an incoming connection is canceled by the caller before it is accepted by the Twilio Client device.
		Device.on('cancel', (connection) => {
			console.log('DEVICE cancel: ', connection)
		})

		Device.on('incoming', (connection) => {
			console.log('DEVICE incoming: ', connection)
		})

		Device.on('offline', (connection) => {
			console.log('DEVICE offline: ', connection)
		})

		Device.on('error', (e) => {
			console.error('DEVICE error', e)
			alert(`There seems to be a problem with your phone device connection. Please refresh the page and try again. ${e && e.message}`)
		})

	}, [])

	useEffect(() => {
		if(!ambassadorId) return

		if(calledTargetLeadActionId) {
			getLeadAction({
				variables: {
					id: calledTargetLeadActionId
				}
			})
		}
	}, [])

	const initiateCall = (number, contactId) => {
		const storeLocalTime = moment().tz(gigData.getGig.time_zone).isBetween()

		if(!number) {
			alert('There is no phone number for this target')
			return
		}

		if(!contactId) {
			alert('Contact ID not available')
			return
		}

		setNumberToCall(number)
		setContactIdToCall(contactId)

		createLeadAction({
			variables: {
				leadAction: {
					brand_id: gigData.getGig.brand_id,
					name: 'Phone Ambassador Outreach',
					action_date: Date.now() + '',
					action_type: 'Call',
					associated_entity_type: 'Contact',
					associated_entity_id: contactId,
					activity_id: gigData.getGig.id,
					associated_ambassador_id: gigData.getGig.field_day_operation_details.assigned_ambassador_id
				}
			}
		})
	}

	const handleCall = (leadActionId) => {
		// if(!numberToCall) {
		// 	alert('There is no phone number for this target')
		// 	return
		// }

		// let timer 

		if (!onPhone) {
			setShowEndCallModal(false)

			// timer = setTimeout(() => {
			// 	createLeadAction({
			// 		variables: {
			// 			leadAction: {
			// 				brand_id: gigData.getGig.brand_id,
			// 				name: 'Phone Ambassador Outreach',
			// 				action_date: Date.now() + '',
			// 				action_type: 'Call',
			// 				associated_entity_type: 'Contact',
			// 				associated_entity_id: contactIdToCall,
			// 				activity_id: gigData.getGig.id,
			// 				associated_ambassador_id: gigData.getGig.field_day_operation_details.associated_ambassador_id
			// 			}
			// 		}
			// 	})
			// }, 10000)

			setMuted(false)
			setOnPhone(true)

			setCalledTarget(filter(contactsData.getContacts.results, contact => contact.id === contactIdToCall)[0])

			if(!numberToCall) {
				alert('Invalid number')
				return
			}

			const n = '+1' + numberToCall.replace(/\D/g, '')

			const connection = Device.connect({
				// number: +16314553328,
				number: n,
				leadActionId,
				city: gigData.getGig.city,
				state: gigData.getGig.state,
			})

			connection.on('accept', (conn) => {
				// console.log('CONNECTION accept: ')
				console.log('CONNECTION accept, status: ', conn.status())
			})

			connection.on('cancel', (conn) => {
				console.log('CONNECTION cancel: ', conn)

				// clearTimeout(timer)
				setNumberToCall(null)
				setContactIdToCall(null)
			})

			connection.on('disconnect', (conn) => {
				console.log('CONNECTION disconnect: ', conn)

				// clearTimeout(timer)
				setNumberToCall(null)
				setContactIdToCall(null)
				setShowEndCallModal(true)
			})

			connection.on('error', (e) => {
				console.error('CONNECTION error: ', e)

				// clearTimeout(timer)
				setNumberToCall(null)
				setContactIdToCall(null)
			})

			// connection.on('mute', (conn) => {
			// 	console.log('CONNECTION mute: ')
			// })

			// connection.on('reject', (conn) => {
			// 	console.log('CONNECTION reject: ')
			// })

			// connection.on('volume', (conn) => {
			// 	console.log('CONNECTION volume: ')
			// })

			setLog('Calling ' + n)
		} else {
			console.log('call eneded by Ambassador')

			Device.disconnectAll()
			setNumberToCall(null)
			setContactIdToCall(null)
			// clearTimeout(timer)
			// setCalledTarget(null)
		}
	}

	const handleMuteClick = () => {
		Device.activeConnection().mute(!muted)
		setMuted(!muted)
	}

	const handleEndCallModalClose = (pickedUp) => {
		if(pickedUp) {
			setShowEndCallModal(false)
		} else {
			setCalledTargetLeadActionId(null)
			setCalledTarget(null)
			setCalledTargetLeadAction(null)
			setShowEndCallModal(false)
			refetchGig()
		}
	}

	const handleCompleteCall = (rating) => {
		editContact({
			variables: {
				input: {
					id: calledTarget.id,
					lead_score: rating,
					top_lead: rating >= 4
				}
			}
		})
	}

	const handleScheduleFollowUpEmail = async (name, email) => {
		if(!email) return

		const brandId = gigData.getGig.brand_id
		const campaign = gigData.getGig.campaign
		const cronTime = `* * * * *`
		const timeZone = gigData.getGig.time_zone
		const sendEmail = gigData.getGig.send_follow_up_email
		const sendGridData = gigData.getGig.sendgrid_data && { ...gigData.getGig.sendgrid_data }
		const sendGridUsername = brandData.getBrand.sendgrid_username
		const sendGridApiKey = brandData.getBrand.sendgrid_api_key
		// const storeNameIds = gigData.getGig.stores.map(store => `Store: ${store.store_name} (${store.store_id})`)
		let sendGridCategories = []
		const storeNames = gigData.getGig.stores.map(store => `Store Name: ${store.store_name}`)
		const storeIds = gigData.getGig.stores.map(store => `Store ID: ${store.store_id}`)
		const regionNames = gigData.getGig.stores.map(store => `Region: ${store.store_region_name}`)

		sendGridCategories = storeNames.concat(storeIds, regionNames)

		sendGridCategories.unshift(`Campaign ID: ${campaign && campaign.campaign_id}`)
		sendGridCategories.unshift(`Gig ID: ${gigData.getGig.id}`)

		if(gigData.getGig.activity_group && gigData.getGig.activity_group.group_master_id) {
			sendGridCategories.unshift(`Master Gig ID: ${gigData.getGig.activity_group.group_master_id}`)
		}

		if(sendEmail) {
			if(sendGridData && sendGridData.template_data) {
				sendGridData.template_data = JSON.parse(sendGridData.template_data)
			}

			sendGridData.categories = sendGridCategories

			try {
				// await axios.post('http://localhost:5000/cronJobs/sendEmailFollowUp', {
				// 	// sender: sender,
				// 	recipient: email,
				// 	cronTime: cronTime,
				// 	timeZone: timeZone,
				// 	// subject,
				// 	// body,
				// 	// brandId,
				// 	contactName: name,
				// 	// imgAttachments: gigData.getGig.attachments ? gigData.getGig.attachments : [],
				// 	sendGridUsername,
				// 	sendGridApiKey,
				// 	sendGridData
				// })

				await axios.post('https://prod.ambassadors.gql.fieldday.app/cronJobs/sendEmailFollowUp', {
					// sender: sender,
					recipient: email,
					cronTime: cronTime,
					timeZone: timeZone,
					// subject,
					// body,
					// brandId,
					contactName: name,
					// imgAttachments: gigData.getGig.attachments ? gigData.getGig.attachments : [],
					sendGridUsername,
					sendGridApiKey,
					sendGridData
				})

				return
			} catch(e) {
				console.error(e)
				return
			}

			return
		}

		if(brandId !== '187'
			&& brandId !== '239'
			&& brandId !== '249'
			&& brandId !== '248'
			&& brandId !== '212'
			&& brandId !== '207'
			&& brandId !== '223'
			&& brandId !== '107'
			&& brandId !== '252'
			&& brandId !== '253'
			&& brandId !== '245'
		) return

		if(brandId === '245') {
			// UFG eligible campaigns
			const eligibleCampaigns = [15081]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		if(brandId === '253') {
			// Sajj eligible campaigns
			const eligibleCampaigns = [15082]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		if(brandId === '207') {
			// Teriyaki Madness eligible campaigns
			const eligibleCampaigns = [15021, 15025, 15043, 15072]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		if(brandId === '212') {
			// Carl'e Jr. eligible campaigns
			const eligibleCampaigns = [15022]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		if(brandId === '223') {
			// Yoga Six eligible campaigns
			const eligibleCampaigns = [15027]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		if(brandId === '187') {
			// Panera eligible campaigns
			const eligibleCampaigns = [15010, 15036, 15026, 7402, 15049, 15061, 15065, 15084, 15092, 15086, 15101]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		if(brandId === '239') {
			// Tropical Smoothie eligible campaigns
			const eligibleStores = [
				14982,
				14984,
				14986,
				14985,
				14980,
				14979,
				14981,
				14983,
				14987,
				7379,
				15000,
				15005,
				15046,
				15047,
				15053,
				15075,
				15068,
				15069,
				15070,
				15074,
				15071,
			]

			if(!campaign || !eligibleStores.includes(campaign.campaign_id)) return
		}

		if(brandId === '252') {
			// Smoothie King eligible campaigns
			const eligibleCampaigns = [15035]

			if(!campaign || !eligibleCampaigns.includes(campaign.campaign_id)) return
		}

		// TODO; make sure hour is calculated using gig's location
		// const hour = moment().add(1, 'hours').hours()
		// const cronTime = `0 0 ${hour} * * *`
		// const cronTime = `0 ${hour} * * *`
		const sender = getEmailSender(gigData.getGig.city, brandId)
		// const cronTime = `* * * * *`
		// const timeZone = gigData.getGig.time_zone
		const subject = getEmailSubject(brandId)
		const body = getEmailBody(brandId, campaign)
		// const storeNameIds = gigData.getGig.stores.map(store => `${store.store_name} (${store.store_id})`)

		// // TODO; fix category adding
		// storeNameIds.unshift(`Campaign ID: ${campaign && campaign.campaign_id}`)

		// console.log('cronTime: ', hour, cronTime)
		// console.log('email: ', email)

		// if(!gigData.getGig.attachments || !gigData.getGig.attachments.length) return

		try {
			// axios.post('http://localhost:5000/cronJobs/sendEmailFollowUp', {
			// 	sender: sender,
			// 	recipient: email,
			// 	cronTime: cronTime,
			// 	timeZone: timeZone,
			// 	subject,
			// 	body,
			// 	brandId,
			// 	storeNameIds,
			// 	contactName: name,
			// 	imgAttachments: gigData.getGig.attachments ? gigData.getGig.attachments : []
			// })

			axios.post('https://prod.ambassadors.gql.fieldday.app/cronJobs/sendEmailFollowUp', {
				sender: sender,
				recipient: email,
				cronTime: cronTime,
				timeZone: timeZone,
				subject,
				body,
				brandId,
				storeNameIds: sendGridCategories,
				contactName: name,
				imgAttachments: gigData.getGig.attachments ? gigData.getGig.attachments : []
			})
		} catch(e) {
			console.error(e)
		}
	}

	const getEmailSender = (city, brandId) => {
		if(brandId === '95') {
			return 'bonefish@localstoreidentity.com'
		}

		if(brandId === '187') {
			// const storeMapping = {
			// 	'St. Louis': 'panera.stlouis@localstoreidentity.com',
			// 	'Chicago': 'panera.chicago@localstoreidentity.com',
			// 	'Dallas': 'panera.dallas@locaslstoreidentity.com',
			// 	'Union': 'panera.newjersey@localstoreidentity.com',
			// 	'Jacksonville': 'panera.jax@localstoreidentity.com',
			// 	'Raleigh': 'panera.raleigh@localstoreidentity.com',
			// 	'Royal Oak': 'panera.detroit@localstoreidentity.com',
			// 	'Minneapolis': 'panera.minneapolis@localstoreidentity.com',
			// 	'Washington': 'panera.washdc@localstoreidentity.com',
			// 	'Phoenix': 'panera.phoenix@localstoreidentity.com',
			// }

			// if(storeMapping[city]) {
			// 	return storeMapping[city]
			// }

			return 'panera@localstoreidentity.com'
		}

		if(brandId === '207') {
			return 'tmadmarketing@teriyakimadness.com'
		}

		if(brandId === '212') {
			return 'carlsjr@localstoreidentity.com'
		}

		if(brandId === '223') {
			return 'yogasix@localstoreidentity.com'
		}

		if(brandId === '239') {
			return 'tropicalsmoothie@localstoreidentity.com'
		}

		if(brandId === '245') {
			return 'greatgreekgrill@localstoreidentity.com'
		}

		if(brandId === '248') {
			return 'primohoagies@localstoreidentity.com'
		}

		if(brandId === '249') {
			return 'potbelly@localstoreidentity.com'
		}

		if(brandId === '252') {
			return 'smoothieking@localstoreidentity.com'
		}

		if(brandId === '253') {
			return 'sajj@localstoreidentity.com'
		}
	}

	const getEmailSubject = (brandId) => {
		const city = gigData.getGig.city
		const campaign = gigData.getGig.campaign

		if(brandId === '187') {
			if(city === 'Springfield') return 'Panera Bread - Springfield MO - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15026) return 'Panera Bread - South Bend - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15036) return 'Panera Bread - Troy, MI - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15049) return 'Panera Bread - Bridgeview, IL - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15061) return 'Panera Bread - Appreciation Gifts For You As Promised'
			if(campaign && campaign.campaign_id === 15065) return 'Panera Bread - Appreciation Gifts for You - Thank You!'
			if(campaign && campaign.campaign_id === 15084) return 'Panera Bread - Green Bay, WI - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15092) return 'Panera Bread - Appreciation Gifts For You As Promised'
			if(campaign && campaign.campaign_id === 15086) return 'Panera Bread - Virginia Beach, VA - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15101) return 'Panera Bread -  End of the Semester Gifts As Promised!'

			return 'Panera Bread - Here is the Information We Promised! Thank You!'
		}

		if(brandId === '95') return 'Happy Holiday Gifting from Bonefish Grill'

		if(brandId === '207') {
			if(campaign && campaign.campaign_id === 15021) return 'Rachel from Teriyaki Madness in Cross Creek'
			if(campaign && campaign.campaign_id === 15025) return 'Zachary from Teriyaki Madness in Lewisville'
			if(campaign && campaign.campaign_id === 15043) return 'Rachel from Teriyaki Madness in Katy'
			if(campaign && campaign.campaign_id === 15072) return 'Megan from Teriyaki Madness in Edmond'

			return ''
		}

		if(brandId === '212') {
			if(campaign && campaign.campaign_id === 15022) return 'Rachael from Carl’s Jr - 5625 W Century Blvd'

			return ''
		}

		if(brandId === '223') return 'YogaSix - Las Colinas - Here is the Information We Promised! Thank You!'

		if(brandId === '239') {
			if(campaign && campaign.campaign_id === 15046) return 'Tropical Smoothie Cafe - Scottsdale, AZ - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15047) return 'Tropical Smoothie Cafe - Knoxville, TN - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15053) return 'Tropical Smoothie Cafe - Ionia, MI - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15075) return 'Tropical Smoothie Cafe - Trussville, AL - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15068) return 'Tropical Smoothie Cafe - Milford, CT - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15069) return 'Tropical Smoothie Cafe - Bloomingdale, IL - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15070) return 'Tropical Smoothie Cafe - Bolingbrook, IL - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15074) return 'Tropical Smoothie Cafe - Memphis, TN - Here is the Information We Promised! Thank You!'
			if(campaign && campaign.campaign_id === 15071) return 'Tropical Smoothie Cafe - Westfield, IN - Here is the Information We Promised! Thank You!'

			return 'Tropical Smoothie Cafe - Houston - Here is the information we promised! Thank you!'
		}

		if(brandId === '245') {
			if(campaign && campaign.campaign_id === 15081) return 'Great Greek Grill - Here is the Information We Promised! Thank You!'

			return 'Geat Greek Offer'
		}

		if(brandId === '249') {
			if(campaign && campaign.campaign_id === 14943) return 'Potbelly Sandwich Shop – Buy One Sandwich Get One Free As Promised! Thank You'
			if(campaign && campaign.campaign_id === 14942) return 'Potbelly Sandwich Shop – Buy One Sandwich Get One Free As Promised! Thank You'
			if(campaign && campaign.campaign_id === 14941) return 'Potbelly Sandwich Shop – Buy One Sandwich Get One Free As Promised! Thank You'

			return 'Potbelly Sandwich Shop – Free Cookie & Drink as Promised! Thank You!'
		}

		if(brandId === '248') {
			if(city === 'Chester Springs') return 'PrimoHoagies – Chester Springs – Here is the Information We Promised! Thank you!'
			if(city === 'Downingtown') return 'PrimoHoagies – Downington – Here is the Information We Promised! Thank you!'
			if(city === 'Swedesboro') return 'PrimoHoagies – Swedesboro – Here is the Information We Promised! Thank you!'
		}

		if(brandId === '252') {
			if(campaign && campaign.campaign_id === 15035) return 'Smoothie King - $3.49 Any 20oz Smoothie - Thank You!'

			return 'Smoothie King Offer'
		}

		if(brandId === '253') {
			if(campaign && campaign.campaign_id === 15082) return 'SAJJ Mediterranean Grill - Here is the Information We Promised! Thank You!'

			return 'Sajj Offer'
		}
	}

	const getEmailBody = (brandId) => {
		const city = gigData.getGig.city
		const attachments = gigData.getGig.attachments
		const campaign = gigData.getGig.campaign

		if(brandId === '187') { // Panera
			const img1 = attachments && attachments.filter(att => att.name === 'Panera-Logo.jpg')

			if(campaign && campaign.campaign_id === 15101) {
				const img1_1 = attachments && attachments.filter(att => att.name === 'current-panera-logo.jpg')
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Half_Field-Day_University_Email - creative.png')
				const img3 = attachments && attachments.filter(att => att.name === 'Bottom_Half_Field-Day_University_Email - creative.png')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1_1 && img1_1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1_1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you so much for taking the time to speak with us earlier.  We know how challenging the end of the semester can be, so we hope these gifts are the perfect push that you need to finish the school year with a bang.
									<br/>
									<br/>
									Below you will find our gifts of: 3 Free Months of our Coffee Subscription Program, Free Delivery through June 15th when ordering through our App or on Panerabread.com, and $3 off our Flatbread Pizzas.....please feel free to share these with co-workers, friends and family.
									<br/>
									<br/>
									Thank you again for your time. We wish you the best during this end of the school year!
									<br/>
									<br/>
									All the best,
									<br/>
									Your Local Panera Bread Family
									<br/>
									<br/>
									${img2 && img2[0] ? `<a href="https://www.panerabread.com/en-us/home.html?openOverlay=order-setup-flow&intcmp=hero-1-hp-c-paneraclassics-c221&overlay=sign-in" style="display: block; width: 650px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img3 && img3[0] ? `<a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription/valpakcoffee.html?promoCode=3movalpak" style="display: block; width: 650px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15092) {
				const img1_1 = attachments && attachments.filter(att => att.name === 'current-panera-logo.jpg')
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Half_Teacher-Appreciation-Day_Email.jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'Bottom_Half_Teacher-Appreciation-Day_Email.jpg')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1_1 && img1_1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1_1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you so much for speaking with us today. Here are the Appreciation Gifts we promised you during our recent phone conversation.  We know it's been an amazing yet challenging year for everyone, and we know that you are the glue that keeps it together, so we are excited to share these with you and your entire team.
									<br/>
									<br/>
									Below you will find our gifts of: 3 Free Months of our Coffee Subscription Program, Free Delivery through June 15th when ordering through our App or on Panerabread.com, and $3 off our Flatbread Pizzas.....please feel free to share these with co-workers, friends and family.
									<br/>
									<br/>
									Thanks for everything you do. We wish you the best this upcoming Teacher Appreciation Week, you deserve it!
									<br/>
									<br/>
									All the best,
									<br/>
									Your Local Panera Bread Family
									<br/>
									<br/>
									${img2 && img2[0] ? `<a href="https://www.panerabread.com/en-us/home.html?openOverlay=order-setup-flow&intcmp=hero-1-hp-c-paneraclassics-c221&overlay=sign-in" style="display: block; width: 650px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img3 && img3[0] ? `<a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription/valpakcoffee.html?promoCode=3movalpak" style="display: block; width: 650px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15086) {
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Image_VA_Beach.jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'Flatbread_Pizzas_VA_Beach.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'MyPaneraCoffee_VA_Beach.jpg')
				const img5 = attachments && attachments.filter(att => att.name === 'Bottom_Left_VA_Beach.jpg')
				const img6 = attachments && attachments.filter(att => att.name === 'Bottom_Right_VA_Beach.jpg')
				const img7 = attachments && attachments.filter(att => att.name === 'Fine_Print_VA_Beach.jpg')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you for speaking with us earlier today.  We really do appreciate your time.
									<br/>
									<br/>
									As mentioned, our Virginia Beach bakery-cafe has recently relocated and is NOW OPEN at 743 Lynnhaven Parkway, featuring a drive-thru!
									<br/>
									<br/>
									Here is the information regarding our $5 Off Online Order* offer for Delivery, Rapid Pick-Up, or Curbside. Offer is valid through <b>May 31, 2021</b>. Redeem online or on the Panera app by entering promo code <b>5FDVAB</b> at checkout.
									<br/>
									<br/>
									Be sure to check out our Coffee Subscription program – UNLIMITED premium coffee for just $8.99 a month. If you subscribe by <b>5/31/21</b>, you’ll get your first 3 months FREE. Simply join our MyPanera loyalty program, if you’re not already a member, and subscribe to <b>MyPanera+ Coffee</b> by visiting <a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html">PaneraBread.com/MyPaneraCoffee</a>. The subscription includes hot coffee, iced coffee or hot tea.
									<br/>
									<br/>
									Thank you for letting us share our special offer with you!  Feel free to share this email with everyone on your team to spread the Panera love.
									<br/>
									<br/>
									Wishing you all the best,
									<br/>
									<br/>
									Your New Neighborhood Panera Bread
									<br/>
									<br/>
									${img2 && img2[0] ? `<a style="display: block; width: 550px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img3 && img3[0] ? `<a style="display: block; width: 550px" href="https://www.panerabread.com/en-us/menu/categories/flatbread-pizza.html"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img4 && img4[0] ? `<a style="display: block; width: 550px" href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img5 && img5[0] ? `<a style="display: inline-block; width: 275px" href="https://www.panerabread.com/content/panerabread_com/en-us/mypanera/sign-up-with-mypanera.html"><img src=${img5[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img6 && img6[0] ? `<a style="display: inline-block; width: 275px" href="http://catering.panerabread.com"><img src=${img6[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									${img7 && img7[0] ? `<a style="display: block; width: 550px"><img src=${img7[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15084) {
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Image_GB.jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'Flatbread_Pizzas_GB.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'MyPaneraCoffee_GB.jpg')
				const img5 = attachments && attachments.filter(att => att.name === 'Bottom_Left_GB.jpg')
				const img6 = attachments && attachments.filter(att => att.name === 'Bottom_Right_GB.jpg')
				const img7 = attachments && attachments.filter(att => att.name === 'Fine_Print_GB.jpg')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you for speaking with us earlier today.  We really do appreciate your time.
									<br/>
									<br/>
									As mentioned, our new Green Bay bakery-cafe is now open and is located at 2370 E. Mason Street, Green Bay, WI 54302.
									<br/>
									<br/>
									Here is the information we promised regarding our $5 Off Online Order* offer for Delivery, Rapid Pick-Up, or Curbside. Redeem online at panerabread.com or on the Panera app through <b>May 31, 2021</b> by entering promo code <b>5FDGBAY</b> at checkout.
									<br/>
									<br/>
									Also, check out our Coffee Subscription program offering unlimited premium coffee to get you through the day, all for just $8.99 a month. And, if you subscribe by <b>5/31/21</b>, you’ll get your first 3 months free. After that, it’s just $8.99/month.* (Restrictions apply.) Simply join our MyPanera loyalty program, if you’re not already a member, and subscribe to <b>MyPanera+ Coffee</b> by visiting <a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html">PaneraBread.com/MyPaneraCoffee</a>. The subscription includes hot coffee, iced coffee or hot tea.
									<br/>
									<br/>
									Thank you for letting us share our special offer with you!  Feel free to share this email with everyone on your team to spread the Panera love.
									<br/>
									<br/>
									Wishing you all the best,
									<br/>
									<br/>
									Your New Neighborhood Panera Bread
									<br/>
									<br/>
									${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 320px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img3 && img3[0] ? `<a href="https://www.panerabread.com/en-us/menu/categories/flatbread-pizza.html" style="display: block; width: 550px; height: 250px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img4 && img4[0] ? `<a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html" style="display: block; width: 550px; height: 200px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img5 && img5[0] ? `<a style="display: inline-block; width: 275px; height: 130px" href="https://www.panerabread.com/content/panerabread_com/en-us/mypanera/sign-up-with-mypanera.html"><img src=${img5[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img6 && img6[0] ? `<a style="display: inline-block; width: 275px; height: 130px" href="http://catering.panerabread.com"><img src=${img6[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img7 && img7[0] ? `<a style="display: block; width: 550px; height: 200px"><img src=${img7[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15065) {
				const img1_1 = attachments && attachments.filter(att => att.name === 'current-panera-logo.jpg')
				const img2 = attachments && attachments.filter(att => att.name === '1256_FLD_Email-Flyer_Admin-Appreciation-Franchise (2).png')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1_1 && img1_1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1_1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you so much for speaking with us today.  Here is the information we promised over the phone.
									<br/>
									<br/>
									&nbsp;&nbsp;&nbsp;&bull; $4 off of any $10 or more purchase: use code 4DELIGHT in- cafe, or when placing an order online or on the app.
									<br/>
									&nbsp;&nbsp;&nbsp;&bull; Receive 10% Back on a Panera Bread Gift Card: use code 10FD when placing an order of $50 or more when you order online at catering.panerabread.com or contact Haley McCurry at (843) 693-3648
									<br/>
									<br/>
									Thanks for everything you do, please feel free to share this offer with everyone on your team.  We wish you the best this upcoming National Administrative Professionals Appreciation Day, you deserve it!
									<br/>
									<br/>
									All the best,
									<br/>
									Your Local Panera Bread Family
									<br/>
									<br/>
									${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 750px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15061) {
				const img1_1 = attachments && attachments.filter(att => att.name === 'current-panera-logo.jpg')
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Half_Admin_Appreciation_Field_Day_Email (1).png')
				const img3 = attachments && attachments.filter(att => att.name === 'Bottom_Half_Admin_Appreciation_Field_Day_Email (1).png')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1_1 && img1_1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1_1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you so much for speaking with us today. Here are the Appreciation Gifts we promised you during our recent phone conversation.  We know it's been an amazing yet challenging year for everyone, and we know that you are the glue that keeps it together, so we are excited to share these with you and your entire team.
									<br/>
									<br/>
									Below you will find our gifts of: 3 Free Months of our Coffee Subscription Program, Free Delivery through May 31st when ordering through our App or on <a href="http://panerabread.com/">Panerabread.com</a>, and $3 off our Flatbread Pizzas.....please feel free to share these with co-workers, friends and family.
									<br/>
									<br/>
									Thanks for everything you do. We wish you the best this upcoming National Administrative Professionals Appreciation Day, you deserve it!
									<br/>
									<br/>
									All the best,
									<br/>
									Your Local Panera Bread Family
									<br/>
									<br/>
									${img2 && img2[0] ? `<a href="https://www.panerabread.com/en-us/home.html?openOverlay=order-setup-flow&intcmp=hero-1-hp-c-paneraclassics-c221&overlay=sign-in" style="display: block; width: 550px; height: 750px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img3 && img3[0] ? `<a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription/valpakcoffee.html?promoCode=3movalpak" style="display: block; width: 550px; height: 400px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15049) {
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Image_Bridgeview.png')
				const img3 = attachments && attachments.filter(att => att.name === 'Flatbread_Pizzas_Bridgeview.png')
				const img4 = attachments && attachments.filter(att => att.name === 'MyPaneraCoffee_Bridgeview.png')
				const img5 = attachments && attachments.filter(att => att.name === 'Bottom_Left_Bridgeview.png')
				const img6 = attachments && attachments.filter(att => att.name === 'Bottom_Right_Bridgeview.png')
				const img7 = attachments && attachments.filter(att => att.name === 'Fine_Print_Bridgeview.png')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you for speaking with us earlier today.  We really do appreciate your time.
									<br/>
									<br/>
									As mentioned, our Burbank bakery-cafe has recently relocated approximately ¼ mile south and is now open at 8115 South Harlem Avenue, featuring a drive-thru.
									<br/>
									<br/>
									Here is the information we promised regarding our $5 Off Online Order* offer for Delivery, Rapid Pick-Up, or Curbside. Redeem online at <a href="http://panerabread.com">panerabread.com</a> or on the Panera app through May 15, 2021 by entering promo code 5FDBVIEW at checkout.
									<br/>
									<br/>
									Also, check out our Coffee Subscription program offering unlimited premium coffee to get you through the day, all for just $8.99 a month. And, if you subscribe by 4/30/21, you’ll get your first 3 months free. After that, it’s just $8.99/month.* (Restrictions apply.) Simply join our MyPanera loyalty program, if you’re not already a member, and subscribe to MyPanera+ Coffee by visiting PaneraBread.com/MyPaneraCoffee. The subscription includes hot coffee, iced coffee or hot tea.
									<br/>
									<br/>
									Thank you for letting us share our special offer with you!  Feel free to share this email with everyone on your team to spread the Panera love.
									<br/>
									<br/>
									Wishing you all the best,
									<br/>
									<br/>
									Your New Neighborhood Panera Bread
									<br/>
									<br/>
									${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 320px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img3 && img3[0] ? `<a href="https://www.panerabread.com/en-us/menu/categories/flatbread-pizza.html" style="display: block; width: 550px; height: 250px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img4 && img4[0] ? `<a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html" style="display: block; width: 550px; height: 200px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img5 && img5[0] ? `<a style="display: inline-block; width: 275px; height: 130px" href="https://www.panerabread.com/content/panerabread_com/en-us/mypanera/sign-up-with-mypanera.html"><img src=${img5[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img6 && img6[0] ? `<a style="display: inline-block; width: 275px; height: 130px" href="http://catering.panerabread.com"><img src=${img6[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img7 && img7[0] ? `<a style="display: block; width: 550px; height: 200px"><img src=${img7[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15026) {
				const img2 = attachments && attachments.filter(att => att.name === '_v2__South__Bend.png')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you for speaking with us earlier today.  We really do appreciate your time.
									<br/>
									<br/>
									As mentioned, our South Bend bakery-cafe has relocated across the street and is NOW OPEN, featuring a Drive-Thru at: 1315 East Ireland Road, South Bend, IN 46614.
									<br/>
									<br/>
									Here is the information we promised regarding our $5 Off Your Online Order* for Delivery, Rapid Pick-Up, Curbside coupon offer. Redeem online at panerabread.com or on the Panera app through <b>April 15, 2021</b> by entering promo code 5FDSBEND at checkout.
									<br/>
									<br/>
									Also, check out our Coffee Subscription program offering unlimited premium coffee to get you through the day, all for just $8.99 a month. And, if you subscribe by <b>3/31/21</b>, you’ll get your first 3 months free. After that, it’s just $8.99/month.* (Restrictions apply.)
									<br/>
									<br/>
									Simply join our MyPanera loyalty program, if you’re not already a member, and subscribe to <b>MyPanera+ Coffee</b> by visiting <a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html">PaneraBread.com/MyPaneraCoffee</a>. The subscription includes hot coffee, iced coffee or hot tea.
									<br/>
									<br/>
									Thank you for letting us share our special offer with you!  Feel free to share this email with everyone on your team to spread the Panera love.
									<br/>
									<br/>
									Wishing you all the best,
									<br/>
									<br/>
									Your New Neighborhood Panera Bread
									<br/>
									<br/>
									${img2 && img2[0] ? `<a style="display: block; width: 650px; height: 1100px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(campaign && campaign.campaign_id === 15036) {
				const img2 = attachments && attachments.filter(att => att.name === 'Top_Troy_MI.png')
				const img3 = attachments && attachments.filter(att => att.name === 'Flatbread_Pizza_Troy_MI.png')
				const img4 = attachments && attachments.filter(att => att.name === 'Coffee_Subscription_Troy_MI.png')
				const img5 = attachments && attachments.filter(att => att.name === 'Bottom_Left_Troy_MI.png')
				const img6 = attachments && attachments.filter(att => att.name === 'Bottom_Right_Troy_MI.png')
				const img7 = attachments && attachments.filter(att => att.name === 'Bottom_Fine_Print_Troy_MI.png')

				return `
					<!DOCTYPE html>
					<div>
						<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
							<tr>
							<td align="left" style="border:0;margin:0;padding:10">
							<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
								${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
								<p>Hello There!</p>
								<p>
									Thank you for speaking with us earlier today.  We really do appreciate your time.
									<br/>
									<br/>
									As mentioned, our Troy bakery-cafe has recently relocated and is now in front of the Oakland Mall, featuring a Drive-Thru at: 390 West 14 Mile Road, Troy, MI 48083.
									<br/>
									<br/>
									Here is the information we promised regarding our $5 Off Your Online Order* for Delivery, Rapid Pick-Up, or Curbside offer. Redeem online at panerabread.com or on the Panera app through <b>April 30, 2021</b> by entering promo code 5FDTROY at checkout.
									<br/>
									<br/>
									Also, check out our Coffee Subscription program offering unlimited premium coffee to get you through the day, all for just $8.99 a month. And, if you subscribe by <b>4/30/21</b>, you’ll get your first 3 months free. After that, it’s just $8.99/month.* (Restrictions apply.)
									<br/>
									<br/>
									Simply join our MyPanera loyalty program, if you’re not already a member, and subscribe to <b>MyPanera+ Coffee</b> by visiting <a href="https://www.panerabread.com/en-us/mypanera/mypanera-coffee-subscription.html">PaneraBread.com/MyPaneraCoffee</a>. The subscription includes hot coffee, iced coffee or hot tea.
									<br/>
									<br/>
									Thank you for letting us share our special offer with you!  Feel free to share this email with everyone on your team to spread the Panera love.
									<br/>
									<br/>
									Wishing you all the best,
									<br/>
									<br/>
									Your New Neighborhood Panera Bread
									<br/>
									<br/>
									${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 320px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img3 && img3[0] ? `<a href="https://www.panerabread.com/en-us/menu/categories/flatbread-pizza.html" style="display: block; width: 550px; height: 250px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img4 && img4[0] ? `<a href="https://www.panerabread.com/en-us/menu/categories/flatbread-pizza.html" style="display: block; width: 550px; height: 190px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img5 && img5[0] ? `<a style="display: inline-block; width: 275px; height: 130px" href="https://www.panerabread.com/content/panerabread_com/en-us/mypanera/sign-up-with-mypanera.html"><img src=${img5[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img6 && img6[0] ? `<a style="display: inline-block; width: 275px; height: 130px" href="http://catering.panerabread.com"><img src=${img6[0].url} style="max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									${img7 && img7[0] ? `<a style="display: block; width: 600px; height: 200px"><img src=${img7[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<br/>
									<br/>
								</p>
							</table>
							</td>
							</tr>
						</table>
					</div>
				`
			}

			if(city === 'Springfield') {
				const img2 = attachments && attachments.filter(att => att.name === '1137_FLD_022221_Field_Day_Catering_Call_Campaign_v1_Traditional_OKC.png' || att.name === '1137_FLD_022221_Field_Day_Catering_Call_Campaign_v2_PBS.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you for speaking with me earlier today. As promised, below are exclusive offers for you and your team. Feel free to share this email with your full organization and spread the Panera love.
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Enjoy $4 off your order of $10 or more</b>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Show this email in cafe or use online code PANERAFAVES at checkout on PaneraBread.com or the app.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (expires 4/15/21)
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Enjoy $20 off your catering order of $100 or more</b>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use online code SAYTHANKS at checkout on PaneraBread.com or the app.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (expires 5/11/21)
										<br/>
										<br/>
										Order your Panera favorites for your upcoming catered meetings and events <a href="https://catering.panerabread.com/">here</a>. We can also provide customized meal solutions for remote meetings as well! Contact Todd Dodge todd.dodge@ilovepanerabread.com to learn more.
										<br/>
										<br/>
										And don’t forget to sign up for MyPanera Rewards Program to receive surprise and delight rewards, exclusive sneak peaks, and more! <a href="https://www.panerabread.com/en-us/mypanera/meet-mypanera.html">Learn More</a>.
										<br/>
										<br/>
										Thank you for letting us share our special offers with you!
										<br/>
										<br/>
										Wishing you all the best,
										<br/>
										<br/>
										Your Neighborhood Panera Bread
										<br/>
										<br/>
										${img2 && img2[0] ? `<a href="https://catering.panerabread.com/" style="display: block; width: 550px; height: 1100px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			} else if(city === 'Santa Maria' || city === 'Oxnard' || city === 'Thousand Oaks') {
				// const img1 = attachments && attachments.filter(att => att.name === 'Panera-Logo.jpg')
				const img2 = attachments && attachments.filter(att => att.name === '1137_FLD_022221_Field_Day_Catering_Call_Campaign_v1_Traditional_OKC.png' || att.name === '1137_FLD_022221_Field_Day_Catering_Call_Campaign_v2_PBS.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you for speaking with me earlier today. As promised, below are exclusive offers for you and your team. Feel free to share this email with your full organization and spread the Panera love.
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Enjoy $4 off your order of $10 or more</b>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Show this email in cafe or use online code PANERAFAVES at checkout on PaneraBread.com or the app.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (expires 4/15/21)
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Enjoy $20 off your catering order of $100 or more</b>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use online code SAYTHANKS at checkout on PaneraBread.com or the app.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (expires 5/11/21)
										<br/>
										<br/>
										Order your Panera favorites for your upcoming catered meetings and events <a href="https://catering.panerabread.com/">here</a>.
										<br/>
										<br/>
										And don’t forget to sign up for MyPanera Rewards Program to receive surprise and delight rewards, exclusive sneak peaks, and more! <a href="https://www.panerabread.com/en-us/mypanera/meet-mypanera.html">Learn More</a>.
										<br/>
										<br/>
										Thank you for letting us share our special offers with you!
										<br/>
										<br/>
										Wishing you all the best,
										<br/>
										<br/>
										Your Neighborhood Panera Bread
										<br/>
										<br/>
										${img2 && img2[0] ? `<a href="https://catering.panerabread.com/" style="display: block; width: 550px; height: 1100px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			} else if(city === 'Newington' || city === 'Southington' || city === 'Providence') {
				// const img1 = attachments && attachments.filter(att => att.name === 'Panera-Logo.jpg')
				const img2 = attachments && attachments.filter(att => att.name === '1137_FLD_022221_Field_Day_Catering_Call_Campaign_v3_Howley.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a href="https://www.panerabread.com/en-us/home.html" style="display: block; width: 650px; height: 75px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you for speaking with me earlier today. As promised, below are exclusive offers for you and your team. Feel free to share this email with your full organization and spread the Panera love.
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Enjoy $7 off your order of $28 or more</b>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Show this email in cafe or use online code 7PANERA at checkout on PaneraBread.com or the app.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (expires 4/15/21)
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Enjoy $20 off your catering order of $100 or more</b>
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use online code SAYTHANKS at checkout on PaneraBread.com or the app.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (expires 5/11/21)
										<br/>
										<br/>
										Order your Panera favorites for your upcoming catered meetings and events <a href="https://catering.panerabread.com/">here</a>. We can also provide customized meal solutions for remote meetings as well! Contact Elizabeth D'Uva at elizabeth.duva@howleybread.com to learn more.
										<br/>
										<br/>
										And don’t forget to sign up for MyPanera Rewards Program to receive surprise and delight rewards, exclusive sneak peaks, and more! <a href="https://www.panerabread.com/en-us/mypanera/meet-mypanera.html">Learn More</a>.
										<br/>
										<br/>
										Thank you for letting us share our special offers with you!
										<br/>
										<br/>
										Wishing you all the best,
										<br/>
										<br/>
										Your Neighborhood Panera Bread
										<br/>
										<br/>
										${img2 && img2[0] ? `<a href="https://catering.panerabread.com/" style="display: block; width: 550px; height: 1100px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			else {
				return '<h1>No Content</h1>'
			}
		}

		if(brandId === '207') { //Teriyaki Madness
			if(campaign && campaign.campaign_id === 15072) {
				const img1 = attachments && attachments.filter(att => att.name === 'TMAD-OKCFieldDay-Final.jpg')
				const img2 = attachments && attachments.filter(att => att.name === 'TMAD_OKC_2021_Takeout_Page_1.jpg')
				const img2_p2 = attachments && attachments.filter(att => att.name === 'TMAD_OKC_2021_Takeout_Page_2.jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'TMAD_OKC_CateringMenu_032020_Page_1.jpg')
				const img3_p2 = attachments && attachments.filter(att => att.name === 'TMAD_OKC_CateringMenu_032020_Page_2.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier. It truly was a pleasure. As promised, attached is the information we promised:
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; Free Regular Sized Bowl
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; $5 Off Any Order of $14
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; $20 Off Any Catering Order of $100
										<br/>
										<br/>
										LOCATION
										<br/>
										We are located at 15124 Lleytons Ct #104, Oklahoma City, OK 73013.
										<br/>
										<br/>
										You can also check out our location page for a copy of our menu, to find links to order and learn more about Teriyaki Madness. Here’s that link: https://teriyakimadness.com/locations/oklahoma-shops-at-penn/
										<br/>
										<br/>
										MENU
										<br/>
										Our online ordering site will provide you with all of our menu options. You can visit https://order.teriyakimadness.com/menu/oklahoma-city or on our app and enter your zip code to find our location and menu.
										<br/>
										<br/>
										CATERING
										<br/>
										To review a copy of our catering menu, visit https://catering.teriyakimadness.com/menu/oklahoma-city-catering
										<br/>
										<br/>
										OFFERS AND OUR APP
										<br/>
										You can go to this page on our website and sign up for a CLUB MAD account: https://teriyakimadness.com/app/
										<br/>
										<br/>
										Or you can go to your favorite app store, download our app, and set up a profile. When you do that for the first time, you will get a FREE BOWL with purchase offer that’s valid for two weeks! And then we send new offers about twice per month.  Plus by setting up an account in the app, your purchases will be tracked so you earn points on purchases for future free TMAD through our rewards program.
										<br/>
										<br/>
										GUIDELINES
										<br/>
										In light of world events, we are taking extra precautions in our dining rooms, all relative to local social distancing and health guidelines.  If you are curious about what those are please take a look at this information on our website: https://teriyakimadness.com/statement/
										<br/>
										<br/>
										GROUP ORDERING
										<br/>
										One last thing, if you are interested, we also offer easy group ordering, online only. But it’s great to allow each person in your group to select their own customized bowl! Go to https://order.teriyakimadness.com/, find the location by entering your zip code, and then after entering your pick up preferences, look to the right for the words “Group Order” next to “Favorites”.
										<br/>
										<br/>
										Thanks again for your time and we look forward to serving you big bowls of awesomeness!
										<br/>
										<br/>
										Best,
										<br/>
										Megan
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										${img2_p2 && img2_p2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img2_p2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										${img3_p2 && img3_p2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img3_p2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15021) {
				const img1 = attachments && attachments.filter(att => att.name === 'GrandOpeningFlyer Fulshear.png')
				const img2 = attachments && attachments.filter(att => att.name === 'TMAD_FulshearTX_TakeoutMenu_02.2021_Page_1.png')
				const img2_p2 = attachments && attachments.filter(att => att.name === 'TMAD_FulshearTX_TakeoutMenu_02.2021_Page_2.png')
				const img3 = attachments && attachments.filter(att => att.name === 'TMAD_FulshearTX_CateringMenu_Page_1.png')
				const img3_p2 = attachments && attachments.filter(att => att.name === 'TMAD_FulshearTX_CateringMenu_Page_2.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier. As promised, I'm following up with details about our upcoming Grand Opening in Cross Creek Ranch, and ask that you share with your colleagues.
										<br/>
										<br/>
										We are located at <b>6300 FM 1463 #300, Fulshear, TX</b>.
										<br/>
										<br/>
										GRAND OPENING DETAILS!
										<br/>
										Our official Grand Opening is on Thursday, March 18th from 11:00am – 10:00pm and we are offering our signature Chicken Teriyaki Bowl (Jr. or Reg. sized) for just $5 BUCKS! It’s an awesome deal that runs from Thurs. March 18th through Fri. March 19th.
										<br/>
										<br/>
										On Sat. March 20th, $1 from every Reg. Sized Bowl purchase will be donated to Fort Bend Seniors Meals on Wheels.
										<br/>
										<br/>
										When you order with an app account between Thurs. March 18th through Wed. March 24th, you will be automatically entered to win FREE TMAD FOR A YEAR (24 bowls, 5 Winners!) or FREE TMAD FOR A MONTH (4 bowls, 20 winners!).
										<br/>
										<br/>
										OFFERS AND OUR APP
										<br/>
										You can go to <a href="https://teriyakimadness.com/app/">this page</a> on our website and sign up for a CLUB MAD account.
										<br/>
										<br/>
										Or you can go to your favorite app store and download our app, and set up a profile. When you do that for the first time you will get a FREE BOWL with a purchase offer that’s valid for two weeks! And then we send new offers about twice per month.  Plus by setting up an account in the app, your purchases will be tracked so you earn points on purchases for future free TMAD through our rewards program.
										<br/>
										<br/>
										MENU
										<br/>
										You can go on our website now to see the offerings of <a href="https://teriyakimadness.com/menu/">our menu</a>.
										<br/>
										<br/>
										GUIDELINES
										<br/>
										In light of world events, we are taking extra precautions in our dining rooms, all relative to local social distancing and health guidelines.  If you are curious about what those are please take a look at this information on <a href="https://teriyakimadness.com/statement/">our website</a>.
										<br/>
										<br/>
										CATERING
										<br/>
										Our Catering program will be up and running in a few weeks.  But if you are curious about what we offer, you can check that out <a href="https://teriyakimadness.com/catering/">here</a>.
										<br/>
										<br/>
										GROUP ORDERING
										<br/>
										One last thing, if you are interested, we also offer easy group ordering, online only. But it’s great to allow each person in your group to select their own customized bowl! You can see that on our <a href="https://order.teriyakimadness.com/">ordering page</a>.
										<br/>
										<br/>
										Thanks again for your time and we look forward to serving you big bowls of awesomeness soon!
										<br/>
										<br/>
										Best,
										<br/>
										Rachel
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										${img2_p2 && img2_p2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img2_p2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										${img3_p2 && img3_p2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img3_p2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15025) {
				const img1 = attachments && attachments.filter(att => att.name === 'large-LewisvilleTakeout.jpg')
				const img2 = attachments && attachments.filter(att => att.name === 'large-LewisvilleCatering.jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'TMAD_Lewisville-5Off10-Flyer3.0.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier. It truly was a pleasure. As promised, here is the information about our $5 Off Any Order of $10 or More coupon offer.
										<br/>
										<br/>
										Valid in-shop, on app, or online orders for the Lewisville location.
										<br/>
										Offer expires: 5/31/2021
										<br/>
										Online Code: 5off10LV
										<br/>
										<br/>
										LOCATION
										<br/>
										We are located at 4770 TX-121 Suite 140 Lewisville, TX near the Lewisville Shopping Mall.
										<br/>
										<br/>
										You can also check out our location page for a copy of our menu, to find links to order and learn more about Teriyaki Madness <a href="https://teriyakimadness.com/locations/tx-lewisville/">here</a>.
										<br/>
										<br/>
										MENU
										<br/>
										Our online ordering site will provide you with all of our menu options. You can visit <a href="https://teriyakimadness.com/locations/tx-lewisville/">here</a> or on our app and enter your zip code to find our location and menu.
										<br/>
										<br/>
										CATERING
										<br/>
										To review a copy of our catering menu, visit <a href="https://catering.teriyakimadness.com/menu/colony-catering">here</a>.
										<br/>
										<br/>
										OFFERS AND OUR APP
										<br/>
										You can go to <a href="https://teriyakimadness.com/app/">this page</a> on our website and sign up for a CLUB MAD account.
										<br/>
										<br/>
										Or you can go to your favorite app store, download our app, and set up a profile. When you do that for the first time, you will get a FREE BOWL with purchase offer that’s valid for two weeks! And then we send new offers about twice per month.  Plus by setting up an account in the app, your purchases will be tracked so you earn points on purchases for future free TMAD through our rewards program.
										<br/>
										<br/>
										GUIDELINES
										<br/>
										In light of world events, we are taking extra precautions in our dining rooms, all relative to local social distancing and health guidelines.  If you are curious about what those are please take a look at this information on <a href="https://teriyakimadness.com/statement/">our website</a>.
										<br/>
										<br/>
										GROUP ORDERING
										<br/>
										One last thing, if you are interested, we also offer easy group ordering, online only. But it’s great to allow each person in your group to select their own customized bowl! Go <a href="https://order.teriyakimadness.com/">here</a>, find the location by entering your zip code, and then after entering your pick up preferences, look to the right for the words “Group Order” next to “Favorites”.
										<br/>
										<br/>
										Thanks again for your time and we look forward to serving you big bowls of awesomeness!
										<br/>
										<br/>
										Best,
										<br/>
										Zach
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 550px; height: 900px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15043) {
				const img1 = attachments && attachments.filter(att => att.name === 'TeriyakiMadness_Logo_V2_CMYK-01.png')
				const img2 = attachments && attachments.filter(att => att.name === 'GrandOpening-Flyer8.5x11-KatyTX (1).jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'TMAD-Updated Coupon -5off10 - Katy v2_Page_1.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'TMAD-Updated Coupon -5off10 - Katy v2_Page_2.jpg')
				const img5 = attachments && attachments.filter(att => att.name === 'TMAD-Katy-CateringCoupon copy 15 percent off 2_Page_1.jpg')
				const img6 = attachments && attachments.filter(att => att.name === 'TMAD-Katy-CateringCoupon copy 15 percent off 2_Page_2.jpg')
				const img7 = attachments && attachments.filter(att => att.name === 'TMAD_KatyTX_TakeoutPreview (1).jpg')
				const img8 = attachments && attachments.filter(att => att.name === 'TMAD_KatyTX_CateringMenu v2_Page_1.jpg')
				const img9 = attachments && attachments.filter(att => att.name === 'TMAD_KatyTX_CateringMenu v2_Page_2.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier. It truly was a pleasure. As promised, here is the information about our Grand Opening as well as our $5 Off Any Order of $10 or More coupon offer.
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; Valid on app or online orders for the Katy location
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; Offer expires: 6/30/2021
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; Online Code: 5off10Katy
										<br/>
										<br/>
										LOCATION
										<br/>
										We are located at 25705 I-10 Katy Freeway STE 110, Katy, TX, 77494.
										<br/>
										<br/>
										GRAND OPENING DETAILS!
										<br/>
										Join us as we celebrate our 100th Shop Opening! Our official Grand Opening is on Thursday, April 1st from 10:00am – 9:00pm and we are offering our signature Chicken Teriyaki Bowl (Jr. or Reg. sized) for just $5 BUCKS! It’s an awesome deal that runs from Thurs. April 1st through Fri. April 2nd.
										<br/>
										<br/>
										And, to celebrate our 100th Shop Opening, the first 100 customers who order in the shop will win a FREE BOWL voucher!
										<br/>
										<br/>
										Earn 100 rewards bonus points on every order placed using your TMAD rewards app on Thur. April 1st.
										<br/>
										<br/>
										On Sat. April 3rd, $1 from every Reg. Sized Bowl purchase will be donated to the Katy Christian Ministry.
										<br/>
										<br/>
										When you order with an app account between Fri. April 2nd through Thurs. April 8th, you will be automatically entered to win FREE TMAD FOR A YEAR (24 bowls, 5 Winners!) or FREE TMAD FOR A MONTH (4 bowls, 20 winners!).
										<br/>
										<br/>
										MENU
										<br/>
										Our online ordering site will provide you with all of our menu options. You can visit https://teriyakimadness.com/menu/ or on our app and enter your zip code to find our location and menu.
										<br/>
										<br/>
										CATERING
										<br/>
										To review a copy of our catering menu, visit https://catering.teriyakimadness.com/ or on our app and enter your zip code to a copy of our catering menu.
										<br/>
										<br/>
										OFFERS AND OUR APP
										<br/>
										You can go to this page on our website and sign up for a CLUB MAD account: https://teriyakimadness.com/app/
										<br/>
										<br/>
										Or you can go to your favorite app store, download our app, and set up a profile. When you do that for the first time, you will get a FREE BOWL with purchase offer that’s valid for two weeks! And then we send new offers about twice per month.  Plus by setting up an account in the app, your purchases will be tracked so you earn points on purchases for future free TMAD through our rewards program.
										<br/>
										<br/>
										GUIDELINES
										<br/>
										In light of world events, we are taking extra precautions in our dining rooms, all relative to local social distancing and health guidelines.  If you are curious about what those are please take a look at this information on our website: https://teriyakimadness.com/statement/
										<br/>
										<br/>
										GROUP ORDERING
										<br/>
										One last thing, if you are interested, we also offer easy group ordering, online only. But it’s great to allow each person in your group to select their own customized bowl! Go to https://order.teriyakimadness.com/, find the location by entering your zip code, and then after entering your pick up preferences, look to the right for the words “Group Order” next to “Favorites”.
										<br/>
										<br/>
										Thanks again for your time and we look forward to serving you big bowls of awesomeness!
										<br/>
										<br/>
										Best,
										<br/>
										Rachel
										<br/>
										<br/>
										http://www.teriyakimadness.com/
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 80px; height: 80px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 650px; height: 600px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 250px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 250px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img5 && img5[0] ? `<a style="display: block; width: 650px; height: 250px"><img src=${img5[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img6 && img6[0] ? `<a style="display: block; width: 650px; height: 250px"><img src=${img6[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img7 && img7[0] ? `<a style="display: block; width: 650px; height: 850px"><img src=${img7[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img8 && img8[0] ? `<a style="display: block; width: 650px; height: 850px"><img src=${img8[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img9 && img9[0] ? `<a style="display: block; width: 650px; height: 850px"><img src=${img9[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			return ''
		}

		if(brandId === '223') { // Yoga Six
			if(campaign && campaign.campaign_id === 15027) {
				const img1 = attachments && attachments.filter(att => att.name === 'LastColinas_Email_V2.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>Hello There!</p>
									<p>
										Thanks so much for speaking with us earlier.  We really do appreciate your time.
										<br/>
										<br/>
										As promised, here is the information regarding our limited time special:
										<br/>
										Sign up for a Warrior 3 Membership and receive ALL STUDIO ACCESS for $94.50/mo.
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; Unlimited Yoga
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; Practice at any 100+ Yoga Six Locations
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; 30 Days of Free Y6Go
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; Offer Ends April 1st
										<br/>
										<br/>
										Contact us and use promo code FD0321 to get started!
										<br/>
										<br/>
										Our studio is located at 4000 N. MacArthur Blvd. Suite 104, Irving, TX 75038, and our phone number is (972) 845-5448 if you would like to give us a call.
										<br/>
										<br/>
										If you would like more information, visit us <a href="https://www.yogasix.com/location/las-colinas">our website</a> to check out our class schedule.  Start with your first class free!
										<br/>
										<br/>
										Thanks again, please feel free to share with everyone on your team.  We hope to see you soon!
										<br/>
										<br/>
										The Las Colinas YogaSix Team!
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 700px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}

		if(brandId === '212') { // Carl's Jr
			if(campaign && campaign.campaign_id === 15022) {
				const img1 = attachments && attachments.filter(att => att.name === 'CJ_142_PrintCoup_FamousStar_10063_030921.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>Hello There!</p>
									<p>
										This is Rachael from Carl’s Jr again.  Thanks so much for speaking with us today about the opening of our new store.
										<br/>
										<br/>
										As promised here is the information we promised regarding our offer:
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; FREE Famous Star Burger with Cheese with the Purchase of a Large Drink
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; Offer valid through 5/31/21
										<br/>
										<br/>
										Our new store is located at <b>5625 W Century Blvd, Los Angeles, CA 90045</b>.
										<br/>
										<br/>
										If you would like more information, here are the links to our store locations page and our menu.
										<br/>
										<br/>
										<a href="https://www.carlsjr.com/locations/ca/los-angeles/5625-w-century-blvd">Store Page</a>
										<br/>
										<a href="https://www.carlsjr.com/menu">Menu</a>
										<br/>
										<br/>
										Thanks again, please feel free to share with everyone on your team.  We hope to see you soon!
										<br/>
										<br/>
										The Carl’s Jr Team!
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 550px; height: 700px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}

		if(brandId === '239') { // Tropical Smoothie
			const img1 = attachments && attachments.filter(att => att.name === 'tsc email header 600x200 v3-03.jpg')
			const img2 = attachments && attachments.filter(att => att.name === 'Email Signature 339x72.png')

			if(campaign && campaign.campaign_id === 15071) {
				const img3 = attachments && attachments.filter(att => att.name === 'TSC_IN-008_299_Smoothie_Coupon.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'TSC_IN-008_20OFF_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us today.  We really do appreciate your time. Attached is the information we promised earlier about our $2.99 24oz Smoothie coupon offer, as well as our $20 Off Any Catering Order of $100 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 661 E State Rd 32, Westfield, IN 46074.
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/in/westfield/661-e-state-rd-32/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=in008&utm_content=location">Tropical Smoothie - 661 E State Rd 32</a>
										<br/>
										<br/>
										and our phone number is: (317) 804-2157 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=in008&utm_content=cater">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										661 E. State Rd 32
										<br/>
										Westfield, IN 46074
										<br/>
										(317) 804-2157
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15074) {
				const img3 = attachments && attachments.filter(att => att.name === '21412.16 TN 49_TSC_Smoothie_Coupon (1).jpg')
				const img4 = attachments && attachments.filter(att => att.name === '21412.16 TN 49_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us earlier.  We really do appreciate your time. Attached is the information we promised earlier about our BUY ONE GET ONE FREE 24oz Smoothie coupon offer, as well as our $10 Off Any Catering Order of $50 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 7706 Winchester Road, Memphis, TN 38125
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/tn/memphis/7706-winchester-road/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=tn049&utm_content=location">Tropical Smoothie - 7706 Winchester Rd</a>
										<br/>
										<br/>
										and our phone number is: (901) 590-3676 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=tn049&utm_content=location">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										7706 Winchester Road
										<br/>
										Memphis, TN 38125
										<br/>
										(901) 590-3676
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15070) {
				const img3 = attachments && attachments.filter(att => att.name === 'TSC_IL-059_299_Smoothie_Coupon.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'TSC_IL-059_20OFF_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us today.  We really do appreciate your time. Attached is the information we promised earlier about our $2.99 24oz Smoothie coupon offer, as well as our $20 Off Any Catering Order of $100 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 740 Janes Ave, Unit 103, Bolingbrook, IL 60440
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/il/bolingbrook/740-janes-ave/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=il059&utm_content=location">Tropical Smoothie - 740 Janes Ave</a>
										<br/>
										<br/>
										and our phone number is: (630) 410-2068 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=il059&utm_content=cater">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										740 Janes Ave, Unit 103
										<br/>
										Bolingbrook, IL 60440
										<br/>
										(630) 410-2068
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15069) {
				const img3 = attachments && attachments.filter(att => att.name === 'TSC_IL-041_299_Smoothie_Coupon.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'TSC_IL-041_20OFF_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us today.  We really do appreciate your time. Attached is the information we promised earlier about our $2.99 24oz Smoothie coupon offer, as well as our $20 Off Any Catering Order of $100 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 1590 Boston Post Rd, Milford, CT 06460.
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/il/bloomingdale/383-army-trail-road/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=il041&utm_content=location">Tropical Smoothie - 383 Army Trail Road</a>
										<br/>
										<br/>
										and our phone number is: (847) 285-1956 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=il041&utm_content=cater">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										383 Army Trail Road
										<br/>
										Bloomingdale, IL 60108
										<br/>
										(847) 285-1956
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15068) {
				const img3 = attachments && attachments.filter(att => att.name === 'CT 4 TSC_Smoothie_Coupon (1).jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'CT 4 TSC_Catering Coupon (2).jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us earlier.  We really do appreciate your time. Attached is the information we promised earlier about our BUY ONE GET ONE FREE 24oz Smoothie coupon offer, as well as our $20 Off Any Catering Order of $100 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 1590 Boston Post Rd Milford, CT 06460.
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/ct/milford/1590-boston-post-rd/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=ct004&utm_content=location">Tropical Smoothie - 1590 Boston Post Rd</a>
										<br/>
										<br/>
										and our phone number is: (203) 283-9101 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=ct004&utm_content=location">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										1590 Boston Post Rd
										<br/>
										Milford, CT 06460
										<br/>
										(203) 283-9101
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15075) {
				const img3 = attachments && attachments.filter(att => att.name === '21412.17 AL 46_TSC_Smoothie_Coupon.jpg')
				const img4 = attachments && attachments.filter(att => att.name === '21412.17 AL 46_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us earlier.  We really do appreciate your time. Attached is the information we promised earlier about our BUY ONE GET ONE FREE 24oz Smoothie coupon offer, as well as our $10 Off Any Catering Order of $50 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 2050 Gadsden Highway, Trussville, AL 35173. 
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/al/trussville/2050-gadsden-highway/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=al046&utm_content=location">Tropical Smoothie - 2050 Gadsden Hwy</a>
										<br/>
										<br/>
										and our phone number is: (205) 537-1058 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=al046&utm_content=location">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										2050 Gadsden Hwy
										<br/>
										Trussville, AL 35173
										<br/>
										(205) 537-1058
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15053) {
				const img3 = attachments && attachments.filter(att => att.name === '21412.14 MI114_TSC_Smoothie_Coupon.jpg')
				const img4 = attachments && attachments.filter(att => att.name === '21412.14 MI114_TSC_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us earlier.  We really do appreciate your time. Attached is the information we promised about our BUY ONE GET ONE FREE 24oz Smoothie coupon offer, as well as our $10 Off Any Catering Order of $50 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 3153 Commerce Lane, Ionia MI 48846.
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/mi/ionia/3153-commerce-lane/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=mi114&utm_content=location">Tropical Smoothie - 3153 Commerce Lane</a>
										<br/>
										<br/>
										and our phone number is: (616) 523-4255 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=mi114&utm_content=location">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										3153 Commerce Lane
										<br/>
										Ionia, MI 48846
										<br/>
										(616) 523-4255
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15046) {
				const img3 = attachments && attachments.filter(att => att.name === '21412.13 AZ39_TSC_Smoothie_Coupon.jpg')
				const img4 = attachments && attachments.filter(att => att.name === '21412.13 AZ39_TSC_Catering_Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us earlier.  We really do appreciate your time. Attached is the information we promised about our BUY ONE GET ONE FREE 24oz Smoothie coupon offer, as well as our $10 Off Any Catering Order of $50 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 15033 North Thompson Peak Parkway, Suite 105, Scottsdale, AZ 85260.
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/az/scottsdale/15033-north-thompson-peak-parkway/?utm_source=fieldday&utm_medium=email&utm_campaign=2021-nowopen&utm_term=az039&utm_content=location">Tropical Smoothie - 15033 North Thompson Peak Parkway</a>
										<br/>
										<br/>
										and our phone number is: (480) 878-5588 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="https://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=az039&utm_content=cater">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										15033 North Thompson Peak Parkway, Suite 105
										<br/>
										Scottsdale, AZ 80526
										<br/>
										(480) 878-5588
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(campaign && campaign.campaign_id === 15047) {
				const img3 = attachments && attachments.filter(att => att.name === 'TSC_4055M_SMOOTHIE COUPON MULTI V2_HI_01 TN.jpg')
				const img4 = attachments && attachments.filter(att => att.name === 'TSC_4055M_CATERING COUPON MULTI V2_HI_02 TN Coupon.jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 200px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for speaking with us earlier.  We really do appreciate your time. Attached is the information we promised about our BUY ONE GET ONE FREE 24oz Smoothie coupon offer, as well as our $20 Off Any Catering Order of $100 or More coupon.
										<br/>
										<br/>
										Our Tropical Smoothie Café location is at: 6481 Kingston Pike, Knoxville,TN 37919.
										<br/>
										<br/>
										Here is a link to our webpage: <a href="https://locations.tropicalsmoothiecafe.com/tn/knoxville/6481-kingston-pike/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=tn039&utm_content=location">Tropical Smoothie - 6481 Kingston Pike</a>
										<br/>
										<br/>
										and our phone number is: (865) 338-9020 - if you would like to give us a call.
										<br/>
										<br/>
										Below is a link to our Party Order menu as well. It’s a great option for working lunches, team meetings, celebrations, and more.
										<br/>
										<br/>
										If your team needs a Pick-me-up, order Smoothies for an afternoon “high five” meeting.  Nothing gives a boost quite like a spontaneous Smoothie party.
										<br/>
										<br/>
										Thanks again for letting us share our Cafe information with you and your team.  We are excited to work with you.  Please share this with anyone else that has a need…. The more the merrier!!
										<br/>
										<br/>
										Here is the link to our website to learn more about our large party order options: <a href="http://www.tropicalsmoothiecafe.com/catering/?utm_source=fieldday&utm_medium=email&utm_campaign=lsm&utm_term=tn039&utm_content=cater">Our Large Party Order Page</a>
										<br/>
										<br/>
										All the best,
										<br/>
										<br/>
										The Tropical Smoothie Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 300px; height: 150px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										6481 Kingston Pike
										<br/>
										Knoxville,TN 37919
										<br/>
										(865) 338-9020
										<br/>
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img4 && img4[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${img4[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			return null
		}

		if(brandId === '248') { // Primo Hoagies
			if(city === 'Chester Springs') {
				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${attachments && attachments[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${attachments[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier today.  We really do appreciate your time.  Here is the $3 Off Any Primo-Size Hoagie or Steak coupon offer that we promised!
										<br/>
										<br/>
										To redeem, simply text keyword CHESTER30OFFPRM to 484.270.4000. Valid through April 30, 2021.
										<br/>
										<br/>
										We have exciting news!
										<br/>
										<br/>
										We are now serving Philly Cheesesteaks! To see more of our gourmet sandwiches, check out our menu <a href="https://www.primohoagies.com/primo-menu.php">here</a>.
										<br/>
										<br/>
										Also attached is  our 20% Off Orders of $25 or More coupon offer, perfect for a spontaneous office snack party! Be sure to try out any of our delicious samplers – Cheese, Pepperoni and Cheese, or Italian – enough to fill all your cravings and plenty to share!
										<br/>
										<br/>
										To redeem, simply text keyword CHESTER20OFF25 to 484.270.4000.  You will be provided with a link, simply follow the prompts and sign up for our PrimoPerks Rewards program.  Current members will have the coupon offer automatically loaded to their account.
										<br/>
										<br/>
										Once you register, your phone number will be linked to your rewards account. Simply provide your phone number, either online or at the front register when placing your order, in order to redeem!
										<br/>
										<br/>
										To learn more about our PrimoPerks rewards program, click <a href="https://www.primohoagies.com/rewards/">here</a>.
										<br/>
										<br/>
										We can’t wait to see you! Our store is located at 155 Pottstown Pike, Chester Springs, PA 19425  and our phone number is (610) 321-0499 if you would like to give us a call!
										<br/>
										<br/>
										All the Best,
										<br/>
										<br/>
										Your Neighborhood PrimoHoagies
										<br/>
										<br/>
										${attachments && attachments[1] ? `<a style="display: block; width: 550px; height: 700px"><img src=${attachments[1].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(city === 'Downingtown') {
				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${attachments && attachments[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${attachments[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier today.  We really do appreciate your time.  Here is the $3 Off Any Primo-Size Hoagie or Steak coupon offer that we promised!
										<br/>
										<br/>
										To redeem, simply text keyword DTOWN30OFFPRM to 484.270.4000. Valid through April 30, 2021.
										<br/>
										<br/>
										We have exciting news!
										<br/>
										<br/>
										We are now serving Philly Cheesesteaks! To see more of our gourmet sandwiches, check out our menu <a href="https://www.primohoagies.com/primo-menu.php">here</a>.
										<br/>
										<br/>
										Also attached is  our 20% Off Orders of $25 or More coupon offer, perfect for a spontaneous office snack party! Be sure to try out any of our delicious samplers – Cheese, Pepperoni and Cheese, or Italian – enough to fill all your cravings and plenty to share!
										<br/>
										<br/>
										To redeem, simply text keyword DTOWN20OFF25 to 484.270.4000.  You will be provided with a link, simply follow the prompts and sign up for our PrimoPerks Rewards program.  Current members will have the coupon offer automatically loaded to their account.
										<br/>
										<br/>
										Once you register, your phone number will be linked to your rewards account. Simply provide your phone number, either online or at the front register when placing your order, in order to redeem!
										<br/>
										<br/>
										To learn more about our PrimoPerks rewards program, click <a href="https://www.primohoagies.com/rewards/">here</a>.
										<br/>
										<br/>
										We can’t wait to see you! Our store is located at 24 East Lancaster Ave, Downington, PA 19335  and our phone number is (484) 247-3855 if you would like to give us a call!
										<br/>
										<br/>
										All the Best,
										<br/>
										<br/>
										Your Neighborhood PrimoHoagies
										<br/>
										<br/>
										${attachments && attachments[2] ? `<a style="display: block; width: 550px; height: 700px"><img src=${attachments[2].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}

			if(city === 'Swedesboro') {
				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${attachments && attachments[0] ? `<a style="display: block; width: 650px; height: 300px"><img src=${attachments[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>Hello There!</p>
									<p>
										Thank you so much for taking the time to speak with us earlier today.  We really do appreciate your time.  Here is the $3 Off Any Primo-Size Hoagie or Steak coupon offer that we promised!
										<br/>
										<br/>
										To redeem, simply text keyword WOOLWICH30OFFPRM to 484.270.4000. Valid through April 30, 2021.
										<br/>
										<br/>
										We have exciting news!
										<br/>
										<br/>
										We are now serving Philly Cheesesteaks! To see more of our gourmet sandwiches, check out our menu <a href="https://www.primohoagies.com/primo-menu.php">here</a>.
										<br/>
										<br/>
										Also attached is  our 20% Off Orders of $25 or More coupon offer, perfect for a spontaneous office snack party! Be sure to try out any of our delicious samplers – Cheese, Pepperoni and Cheese, or Italian – enough to fill all your cravings and plenty to share!
										<br/>
										<br/>
										To redeem, simply text keyword WOOLWICH20OFF25 to 484.270.4000.  You will be provided with a link, simply follow the prompts and sign up for our PrimoPerks Rewards program.  Current members will have the coupon offer automatically loaded to their account.
										<br/>
										<br/>
										Once you register, your phone number will be linked to your rewards account. Simply provide your phone number, either online or at the front register when placing your order, in order to redeem!
										<br/>
										<br/>
										To learn more about our PrimoPerks rewards program, click <a href="https://www.primohoagies.com/rewards/">here</a>.
										<br/>
										<br/>
										We can’t wait to see you! Our store is located at 120 Center Square Rd, Swedesboro, NJ 08085  and our phone number is (856) 241-1226 if you would like to give us a call!
										<br/>
										<br/>
										All the Best,
										<br/>
										<br/>
										Your Neighborhood PrimoHoagies
										<br/>
										<br/>
										${attachments && attachments[2] ? `<a style="display: block; width: 550px; height: 700px"><img src=${attachments[2].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}

		if(brandId === '245') { // Great Greek
			if(campaign && campaign.campaign_id === 15081) {
				const img1 = attachments && attachments.filter(att => att.name === 'The Great Greek_OpaPalooza Flyer_Skyhouse Orlando (1).png')
				const img2 = attachments && attachments.filter(att => att.name === 'SKYHOUSE ORLANDO The Great Greek_To Go Menu_OUTSIDE (1).jpg')
				const img3 = attachments && attachments.filter(att => att.name === 'SKYHOUSE ORLANDO The Great Greek_To Go Menu_INSIDE (1).jpg')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									<p>
										Hello there
										<br/>
										<br/>
										Thank you so much for speaking with us earlier today.  We really do appreciate your time.  As promised, here is the information regarding our Grand Opening next week.
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; April 19th – 20th : BUY ONE GET ONE FREE GYRO
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; April 21st : CHARITY DAY
										<br/>
										&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; 50% of Net Sales will be donated to Give Kids the World
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; April 22nd – 23rd : FREE APPETIZER OR DESSERT
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; with the purchase of any entrée or Gyro combo
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; April 24th – 25th : KIDS EAT FREE
										<br/>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; with the purchase of entrée or Gyro combo
										<br/>
										<br/>
										Our new store is located at: 335 N Magnolia Ave in Downtown Orlando (across the street from the Courthouse).
										<br/>
										<br/>
										I’ve also attached our promotional flyer to this email that you can share with everyone on your team.  Please feel free to let everybody know!
										<br/>
										<br/>
										Below is a link to our location’s website and our catering page.
										<br/>
										<br/>
										Our Website -  <a href="https://www.thegreatgreekgrill.com/orlando-fl">Great Greek Grill Orlando</a>
										<br/>
										<br/>
										Our Catering Page -  <a href="https://www.thegreatgreekgrill.com/orlando-fl-catering">Orlando Catering Menu</a>
										<br/>
										<br/>
										I hope you can visit us soon – we’d love to see you!
										<br/>
										<br/>
										All the best!
										<br/>
										<br/>
										Your local Great Greek Mediterranean Grill Team
										<br/>
										<br/>
										${img1 && img1[0] ? `<a style="display: block; width: 650px; text-align: center"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 650px; text-align: center"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										${img3 && img3[0] ? `<a style="display: block; width: 650px; text-align: center"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}

		if(brandId === '249') { // Potbelly
			if(campaign && (campaign.campaign_id === 14943 || campaign.campaign_id === 14942 || campaign.campaign_id === 14941)) {
				const img1 = attachments && attachments.filter(att => att.name === 'FieldEmail.png')
				const img2 = attachments && attachments.filter(att => att.name === 'Potbelly_CIN BOGO QR Code.png')
				const img3 = attachments && attachments.filter(att => att.name === '4294967394_2021021010465498.jpg')

				if(!img1.length && !img2.length) return null

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 400px"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>
										<h1 style="text-align: center">Save room for dessert</h1>
										<h2 style="text-align: center">(If you can)</h2>
										<br/>
										We interrupt your boring business emails with great news: <b>Our toasty sandwiches are now bigger than ever.</b> We're also adding <b>more meat and cheese</b> into every sandwich, all toasted to perfection in our 500F oven.
										<br/>
										<br/>
										So taste our big new changes, and bring a friend because your second sandwich is on us:
										<br/>
										<h1 style="text-align: center">Buy One Sandwich Get One Free</h1>
										<h2 style="text-align: center">To redeem this offer</h2>
										<h3 style="text-align: center">Just show this email during your next visit</h3>
										<h3 style="text-align: center">or scan the QR code to order ahead for pickup and delivery!</h3>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 170px; height: 170px"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<b>Potbelly Perks members:</b> this offer will be loaded into your app after you scan.
										<br/>
										<b>Not a Perks member?</b> Scan the code, enter your email, and click the download link. You'll get our app, where you can order ahead, order delivery, and earn points towards free food!
										<br/>
										<h6>Valid in Cincinnati area shops. Expires 4/15/21. Limit one (1) per customer per order.</h6>
										<h1 style="text-align: center">When we say bigger, we mean it</h1>
										${img3 && img3[0] ? `<a style="display: block; width: 650; height: 200px"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										Bigger sandwiches, bigger flavors, all melded together in our oven! So your sandwich isn't just bigger: <b>It's more satisfying than ever, too.</b>
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}

		if(brandId === '252') { // Smoothie King
			if(campaign && campaign.campaign_id === 15035) {
				const img1 = attachments && attachments.filter(att => att.name === '031121_WK10_LSM_DIG_EMAIL_FieldDay_KWAPP_01.png')
				const img2 = attachments && attachments.filter(att => att.name === '031121_WK10_LSM_DIG_EMAIL_FieldDay_KWAPP_02.jpeg')
				const img3 = attachments && attachments.filter(att => att.name === '031121_WK10_LSM_DIG_EMAIL_FieldDay_KWAPP_03.jpeg')

				if(!img1.length && !img2.length) return null

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a style="display: block; width: 650px; height: 100px; text-align: center"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>
										Hello there, and thank you for speaking with us today.
										<br/>
										<br/>
										As promised, enclosed is your exclusive offer, $3.49 for any 20 oz. smoothie. This offer can be used only in-store at any of our local Smoothie King locations. Try any of our great smoothies like Caribbean Way or Angel Food.  Or try one of our New Stretch & Flex smoothies, Tart Cherry or Pineapple Kale!
										<br/>
										<br/>
										Please feel free to share this offer with your coworkers, family, and friends!
										<br/>
										<br/>
										${img2 && img2[0] ? `<a href="https://locations.smoothieking.com/" style="display: block; width: 650px; text-align: center"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										${img3 && img3[0] ? `<a href="https://www.smoothieking.com/menu/smoothies" style="display: block; width: 650; text-align: center"><img src=${img3[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
										<h6 style="text-align: center">Some restrictions may apply. Valid in store only at participating locations. Excludes extras & enhancers. Not valid with kid's cups or any other offer. Not valid on 32 oz. smoothies on Fridays. Limit one per person per store visit. No cash value. Sales Tax extra. Offer expires on 4/30/21.</h6>
										<h5 style="text-align: center">
											©2021 Smoothie King Franchises, Inc. All rights reserved.
											<br />
											9797 Rombauer Rd. Suite 150 Dallas, TX 75019
										</h5>
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}

		if(brandId === '253') { // Sajj
			if(campaign && campaign.campaign_id === 15082) {
				const img1 = attachments && attachments.filter(att => att.name === 'Dark Logo EPS.png')
				const img2 = attachments && attachments.filter(att => att.name === 'Offer Flyer (Final)_Page_2.png')

				return `
						<!DOCTYPE html>
						<div>
							<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" bgcolor="#ffffff" style="border:0;margin:0;padding:10">
								<tr>
								<td align="left" style="border:0;margin:0;padding:10">
								<table align="left" border="0" cellpadding="0" cellspacing="0" width="650" id="emailContainer" bgcolor="ffffff" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;margin:0 auto;min-width:650px;background-color: white">
									${img1 && img1[0] ? `<a href="https://www.sajjstreeteats.com/" style="display: block; width: 650px; height: 225px; text-align: center"><img src=${img1[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
									<p>
										Hello there
										<br/>
										<br/>
										Thank you so much for speaking with us earlier today.  We really do appreciate your time.  As promised, here is the information regarding our offers, available through May 2021:
										<br/>
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; $7 OFF or BUY ONE ENTREE GET ONE FREE when you create your own custom promo code to share with others.
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; $25 OFF all catering orders of $100+ and FREE DELIVERY on all orders of $125+ with promo code: CATER25
										<br/>
										&nbsp;&nbsp;&nbsp;&bull; $25 OFF all market orders of $100+ and FREE NEXT-DAY DELIVERY on all orders of $125+ with promo code: MARKET25
										<br/>
										<br/>
										Halal proteins and fresh locally sourced ingredients go directly from farm-to-table, all customized to your preference. Explore the Restaurant, Catering, and Market Menus for your nearest location here: https://www.sajjstreeteats.com/order/
										<br/>
										<br/>
										We have one last offer….and it’s just for you. When your company or team redeems any one of these offers for work, we will send you a $25 SAJJ gift card.
										<br/>
										<br/>
										Contact us at contact@sajjstreeteats.com for more info.
										<br/>
										<br/>
										All the best!
										<br/>
										<br/>
										Your Local SAJJ Mediterranean Team
										<br/>
										<br/>
										${img2 && img2[0] ? `<a style="display: block; width: 650px; text-align: center"><img src=${img2[0].url} style="display:block; max-width: 100%; max-height: 100%" alt="" /></a>` : ''}
										<br/>
										<br/>
									</p>
								</table>
								</td>
								</tr>
							</table>
						</div>
						`
			}
		}
	}

	const getContactsForTable = () => {
		if(!gigData || !gigData.getGig || !contactsData) return null

		const phoneTargets = gigData.getGig.field_day_operation_details.phone_targets
		const contacts = contactsData.getContacts.results
		const toCallBackStatus = ['CALL_BACK', 'NO_ANSWER', 'BUSY_SIGNAL', 'ENDED_BY_AMBASSADOR', 'VOICEMAIL']
		// const notToCallBackStatus = ['SUCCESSFUL', 'INVALID_NUMBER']
		const targetsToCall = []
		const targetsToCallBack = []

		each(phoneTargets, target => {
			if(!target.was_called) {
				targetsToCall.push(target)
			}

			if(target.was_called && toCallBackStatus.includes(target.phone_exception_reason)) {
				targetsToCallBack.push(target)
			}
		})

		const contactsToCallBack = filter(contacts, contact => some(targetsToCallBack, target => target.contact_id === contact.id))
		const contactsToCall = filter(contacts, contact => some(targetsToCall, target => target.contact_id === contact.id))

		const sortedContactsToCall = sortBy(contactsToCall, contact => Number(contact.employee_size)).reverse()
		const finalResults = sortedContactsToCall.concat(contactsToCallBack)

		return finalResults
	}

	if(!ambassadorId) {
		return (
			<Redirect
				// push
				to={{
					pathname: '/login',
				}}
			/>
		)
	}

	// console.log('loadingGig: ', loadingGig)
	console.log('gigData: ', gigData)
	// console.log('brandData: ', brandData)
	// console.log('loadingContacts: ', loadingContacts)
	// console.log('contactsData: ', contactsData)
	// console.log('calledTarget: ', calledTarget)
	// console.log('onPhone: ', onPhone)
	// console.log('log: ', log)
	// console.log('calledTargetLeadAction: ', calledTargetLeadAction)
	// console.log('calledTargetLeadActionId: ', calledTargetLeadActionId)
	// console.log('showEndCallModal: ', showEndCallModal)
	// console.log('isIdle: ', isIdle)

	return (
		<Container>
			{isIdle && !onPhone &&
				<IdleModal />
			}
			{showEndCallModal && calledTargetLeadActionId &&
				<EndCallModal
					onClose={handleEndCallModalClose}
					onShowModal={setShowEndCallModal}
					gigId={gigId}
					calledTargetLeadActionId={calledTargetLeadActionId}
					calledTargetId={gigData && calledTarget &&
						gigData.getGig.field_day_operation_details.phone_targets.filter(t => t.contact_id === calledTarget.id)[0].target_id
					}
				/>
			}
			{showRatelModal &&
				<RateModal onCompleteCall={handleCompleteCall} />
			}
			{showKeypad &&
				<DTMFTone
					device={Device}
					showModal={showKeypad}
					onClose={() => setShowKeypad(false)}
				/>
			}
			<div className='phone-gig-bg'></div>
			<StatusBar
				gig={gigData && gigData.getGig}
				totalCallsToMake={getContactsForTable()}
				numOfEmailsCollected={contactsData && contactsData.getContacts.results.filter(c => c.contact_email).length}
			/>
			<CallBar
				onPhone={onPhone}
				calledTargetLeadActionId={calledTargetLeadActionId}
				onKeypadClick={() => setShowKeypad(true)}
				onMuteClick={handleMuteClick}
				muted={muted}
				onHangUp={handleCall}
				// onFinishingDetail={() => setShowRatelModal(true)}
				calledTargetId={gigData && calledTarget &&
					gigData.getGig.field_day_operation_details.phone_targets.filter(t => t.contact_id === calledTarget.id)[0].target_id
				}
			/>
			<Row className='mt-3'>
				<Col md={4} style={{ fontSize: '14px' }}>
					<Button
						variant='info'
						className='w-100 fake-btn'
						onClick={null}
					>
						<b>Objective:</b> {gigData && gigData.getGig.purpose}
					</Button>
					{/*<Button onClick={scheduleFollowUpEmail}>sendEmailFollowUp</Button>*/}
					<WhatToSay gig={gigData && gigData.getGig} />
				</Col>
				{(onPhone || calledTargetLeadActionId) && calledTarget &&
					<Fragment>
						<Col>
							<div style={{ height: '200px', marginBottom: '60px' }}>
								<DistanceMap
									calledTarget={calledTarget}
									gigLocation={gigData.getGig.loc_point}
								/>
							</div>
							<Notes
								leadActionId={calledTargetLeadActionId}
								setNotesExist={setNotesExist}
							/>
						</Col>
						<Col>
							<TargetForm
								gigId={gigId}
								brandId={gigData && gigData.getGig.brand_id}
								campaignId={gigData && gigData.getGig.campaign && gigData.getGig.campaign.campaign_id}
								calledTarget={calledTarget}
								gigLocation={gigData.getGig.loc_point}
								leadActionId={calledTargetLeadActionId}
								onShowRateModal={() => setShowRatelModal(true)}
								onScheduleFollowUpEmail={handleScheduleFollowUpEmail}
								notesExist={notesExist}
								setNotesExist={setNotesExist}
								onEndCallAction={endCallAction}
								calledTargetId={gigData && calledTarget &&
									gigData.getGig.field_day_operation_details.phone_targets.filter(t => t.contact_id === calledTarget.id)[0].target_id
								}
							/>
						</Col>
					</Fragment>
				}
				{(!onPhone && !calledTargetLeadActionId) &&
					<Col className='phone-gig-map'>
						{loadingContacts && loadingGig &&
							<div className='d-flex h-100'>
								<Spinner animation='grow' variant='info' className='m-auto' />
							</div>
						}
						{contactsData && gigData &&
							<TargetMap contacts={contactsData.getContacts} gig={gigData.getGig} />
						}
						<TargetTable
							// contacts={contactsData && contactsData.getContacts}
							// onCall={handleCall}
							contacts={contactsData && contactsData.getContacts && getContactsForTable()}
							onCall={initiateCall}
							gigLocation={gigData && gigData.getGig.loc_point}
						/>
					</Col>
				}
			</Row>
		</Container>
	)
}

const GET_GIG = gql`
	query GetGig($gigId: ID!) {
		getGig(gigId: $gigId) {
			id
			activity_group {
				group_master_id
			}
			campaign {
				campaign_id
			}
			title
			brand_id
			brand_name
			brand_logo_url
			due_date_millis
			description
			script
			best_practices
			brand_details
			purpose
			tactics
			state
			city
			address
			postal_code
			loc_point
			time_zone
			time_zone_human
			send_follow_up_email
			stores {
				store_id
				store_name
				store_region_name
			}
			attachments {
				name
				url
			}
			field_day_management {
				field_day_remuneration {
					max_hours
				}
			}
			field_day_operation_details {
				assigned_ambassador_id
				phone_targets {
					target_id
					was_called
					start_time
					end_time
					duration
					picked_up
					left_voicemail
					contact_id
					lead_action_id
					phone_exception_reason
				}
			}
			sendgrid_data {
				email_subject
				sender_name
				sender_email_address
				template_id
				template_data
				reply_to
			}
		}
	}
`

const GET_BRAND = gql`
	query GetBrand($brandId: String) {
		getBrand(brandId: $brandId) {
			sendgrid_username
			sendgrid_api_key
		}
	}
`

const GET_CONTACTS = gql`
	query GetContacts($gigId: ID) {
		getContacts(gigId: $gigId) {
			total
			results {
				id
				company_name
				contact_name
				contact_title
				company_address
				city
				state
				postal_code
				coordinates
				phone_1
				phone_3
				contact_email
				frequency_of_need
				next_steps
				employee_size
				questions {
					question
					answer
				}
			}
		}
	}
`

const GET_TWILIO_TOKEN = gql`
	query GetToken($key: String) {
		getToken(key: $key)
	}
`

const CREATE_LEAD_ACTION = gql `
	mutation CreateLeadAction($leadAction: CreateLeadActionInputs) {
		createLeadAction(leadAction: $leadAction) {
			id
		}
	}
`

const GET_LEAD_ACTION = gql `
	mutation GetLeadAction($id: ID!) {
		getLeadAction(id: $id) {
			id
			note
			person_reached_name
		}
	}
`

const UPDATE_TARGET = gql`
	mutation EditTarget($gigId: ID!, $targetId: ID!, $target: TargetInputs) {
		editTarget(gigId: $gigId, targetId: $targetId, target: $target) {
			target_id
			was_called
			start_time
			end_time
			duration
			picked_up
			left_voicemail
			contact_id
			lead_action_id
			phone_exception_reason
		}
	}
`

const EDIT_CONTACT = gql`
	mutation EditContact($input: EditContactInputs) {
		editContact(input: $input) {
			id
		}
	}
`
