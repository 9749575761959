import React from 'react'
import { Redirect } from 'react-router-dom'

function Home() {
	const ambassadorId = localStorage.getItem('ambassadorId')
	const gigId = localStorage.getItem('gigId')

	if(!ambassadorId || !gigId) {
		return (
			<Redirect
				// push
				to={{
					pathname: '/login'
				}}
			/>
		)
	}

	return (
		<Redirect
			// push
			to={{
				pathname: '/phoneGig',
				search: `id=${gigId}`,
				// TODO; figure out why this isn't passing through
				// state: { gigId: upcomingPhoneGigs[0].id }
			}}
		/>
	)
}

export default Home
