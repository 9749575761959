import React from 'react'
import { Modal, Col, Button } from 'react-bootstrap'

export default function IdleModal() {
	// const [rating, setRating] = useState(null)

	return (
		<Modal
			centered
			show={true}
			// size='lg'
			// onHide={() => {}}
		>
			<Modal.Body className='text-center p-5'>
				<h3>
					Hello! We see you’ve stepped away - please let us know when you’re back since we’re tracking time on this campaign.
				</h3>
			</Modal.Body>
			<div
				className='custom-green-btn pl-5 pr-5 m-1 pointer fd-green'
				// onClick={onStopIdle}
				style={{
					position: 'absolute',
					bottom: '-15px',
					left: '34%',
					width: '170px'
				}}
			>
				I'm back!
			</div>
		</Modal>
	)
}
