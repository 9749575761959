import React from 'react'
import { compose, withProps, withStateHandlers, lifecycle } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'

import measureDistance  from '../../utils/measureDistance'

const googleApiKey = 'AIzaSyBzt4U31ue3BGvUsNj0v4MSKjm87sQLSzc'

const DistanceMap = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)((props) => {
	if(!props.calledTarget.coordinates) return null

	const markers = []
	const bounds = new window.google.maps.LatLngBounds()
	const storeIcon = {
		path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    scale: 1,
    fillColor: "#0699a8",
    fillOpacity: 1,
    strokeWeight: 1
	}

	markers.push(
		<Marker
			key={`store`}
			icon={storeIcon}
			position={{ lng: Number(props.gigLocation[0]), lat: Number(props.gigLocation[1]) }}
		/>,
		<Marker
			key={'target'}
			position={{ lng: Number(props.calledTarget.coordinates[0]), lat: Number(props.calledTarget.coordinates[1]) }}
		/>,
	)

	bounds.extend({ lng: Number(props.gigLocation[0]), lat: Number(props.gigLocation[1]) })
	bounds.extend({ lng: Number(props.calledTarget.coordinates[0]), lat: Number(props.calledTarget.coordinates[1]) })

	return (
		<div>
			<GoogleMap
				ref={map => map && map.fitBounds(bounds)}
				// defaultZoom={15}
				// defaultCenter={{ lng: Number(props.gig.loc_point[0]), lat: Number(props.gig.loc_point[1]) }}
			>
			{markers}
			</GoogleMap>
			<div className='font-weight-bold mt-1' style={{ color: '#9A2BFB' }}>{props.calledTarget.company_name}</div>
			<div style={{ color: '#a3a3a3', fontSize: '11px', fontWeight: '500' }}>
				{props.calledTarget.company_address}, {props.calledTarget.city}&nbsp;
				({measureDistance(
					{ lng: Number(props.gigLocation[0]), lat: Number(props.gigLocation[1]) },
					{ lng: Number(props.calledTarget.coordinates[0]), lat: Number(props.calledTarget.coordinates[1]) }
				)}mi away)
			</div>
		</div>
	)
})

export default DistanceMap
