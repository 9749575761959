import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import App from './App'

const client = new ApolloClient({
	// uri: process.env.REACT_APP_STAGE === 'prod' ? 'https://prod.ambassadors.gql.fieldday.app/' : 'http://localhost:7000'
	// uri: 'http://localhost:5000/graphql',
	uri: 'https://prod.ambassadors.gql.fieldday.app/graphql',
	cache: new InMemoryCache()
})

export default (
	<Router>
		<ApolloProvider client={client}>
		  <App />
		</ApolloProvider>
	</Router>
)
