import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import moment from 'moment-timezone'
import { filter } from 'lodash'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { useAppContext } from '../../utils/contextUtil'

export default function StatusBar({ gig, totalCallsToMake, numOfEmailsCollected }) {
	const [gigEnded, setGigEnded] = useState(false)
	const { setIsAuthenticated } = useAppContext()

	const [endGig] = useMutation(END_GIG, {
		onCompleted(data) {
			setGigEnded(true)
		}
	})

	if(!gig) return <em>loading..</em>

	const handleEndGig = () => {
		const confirm = window.confirm('Are you sure you want to end this gig? You will be signed out.')

		if(confirm) {
			endGig({
				variables: { id: gig.id }
			})
		}
	}

	if(gigEnded) {
		localStorage.removeItem('ambassadorId')
		localStorage.removeItem('gigId')

		setIsAuthenticated(false)

		return (
			<Redirect
				// push
				to={{
					pathname: '/login'
				}}
			/>
		)
	}

	return (
		<Row className='pt-3' noGutters>
			<Col md={4}>
				<Brand
					logoKey={gig.brand_logo_url}
					brandName={gig.brand_name}
					gigTitle={gig.title}
					dueDate={Number(gig.due_date_millis)}
					maxHours={Number(gig.field_day_management.field_day_remuneration.max_hours)}
					timeZone={gig.time_zone}
					gig={gig}
				/>
				<Button variant='secondary' className='w-75 ml-4' onClick={handleEndGig}>End Gig</Button>
			</Col>
			<Col className='d-flex flex-column'>
				<Row noGutters>
					<Col>
						<StatusTile
							title={filter(gig.field_day_operation_details.phone_targets, target => target.was_called).length}
							subTitle='Businesses called today'
						/>
					</Col>
					<Col>
						<StatusTile
							title={gig.field_day_operation_details.phone_targets && gig.field_day_operation_details.phone_targets.length}
							subTitle='Total businesses to call'
						/>
					</Col>
					{/*<Col>
						<StatusTile
							title={filter(gig.field_day_operation_details.phone_targets, target => (target.was_called && !target.picked_up)).length}
							subTitle={'Did not pick up'}
						/>
					</Col>*/}
					<Col>
						<StatusTile
							title={numOfEmailsCollected}
							subTitle={'Emails collected'}
						/>
					</Col>
					<Col>
						<StatusTile
							showClock
							title={moment().tz(gig.time_zone).format('h:mma')}
							subTitle={gig.time_zone_human}
							timeZone={gig.time_zone}
						/>
					</Col>
				</Row>
				<Row className='align-items-center mt-2' noGutters>
					<div className='mr-2' style={{ fontSize: '12px' }}>
						{totalCallsToMake && totalCallsToMake.length} more calls to make
					</div>
					<ProgressBar
						variant='warning'
						// className='fd-bg-green'
						min={0}
						max={gig.field_day_operation_details.phone_targets && gig.field_day_operation_details.phone_targets.length}
						now={filter(gig.field_day_operation_details.phone_targets, target => target.was_called).length}
						style={{ width: '400px' }}
					/>
				</Row>
			</Col>
		</Row>
	)
}

function Brand({ logoKey, brandName, gigTitle, dueDate, maxHours, timeZone, gig }) {console.log('HEREEEE: ', gig)
	// const endTime = moment(dueDate).add(maxHours, 'hours').format('h:mm')

	const { loading: loadingImg, error: imgError, data: imgUrl } = useQuery(GET_BRAND_LOGO, {
		variables: {
			key: logoKey
		}
	})

	return (
		<Row className='status-bar-brand'>
			<Col md={4}>
				<img src={imgUrl && imgUrl.getAwsUrl} alt='' />
			</Col>
			<Col>
				<h3 className='mb-0'>{brandName}</h3>
				<div>{gigTitle}</div>
				<div className='mt-2 mb-2'>
					<div className='font-weight-bold'><u>Store Location</u></div>
					<div>{gig.address}</div>
					<div>{gig.city}, {gig.state}</div>
				</div>
				<div className='mt-2 mb-2'>
					<div className='font-weight-bold'><u>Due by</u></div>
					<div>{moment(dueDate).tz(timeZone).format('MMM Do - h:mma z')}</div>
					{/*<h6>{moment(dueDate).tz(timeZone).format('ddd, MMM Do')}</h6>*/}
					{/*<h6>{moment(dueDate).tz(timeZone).format(`h:mma - ${endTime}a z`)}</h6>*/}
				</div>
			</Col>
		</Row>
	)
}

function StatusTile({ title, subTitle, showClock, timeZone }) {
	const [currentTime, setCurrentTime] = useState(Date.now())

	useEffect(() => {
		if(showClock) {
			const timer = setInterval(() => setCurrentTime(Date.now()), 1000)

			return () => clearInterval(timer)
		}
	}, [currentTime])

	return (
		<div
			className={`text-center fd-green ${showClock ? 'border-right' : ''} border-left border-info`}
			style={{ padding: '10px', height: '100%' }}
		>
			<h1 className='font-weight-bold'>{showClock ? moment(currentTime).tz(timeZone).format('h:mma') : title}</h1>
			<div>{subTitle}</div>
			{showClock &&
				<div style={{ fontSize: '12px' }}>
					(Local store time)
				</div>
			}
		</div>
	)
}

const GET_BRAND_LOGO = gql`
	query GetAwsUrl($key: String!) {
		getAwsUrl(key: $key)
	}
`

const END_GIG = gql`
	mutation EndGig($id: ID!) {
		endGig(id: $id)
	}
`

