import React, { useState, useEffect } from 'react'

function Timer() {
	const [timeLapsed, setTimeLapsed] = useState(0)
	const [seconds, setSeconds] = useState(0)
	const [minutes, setMinutes] = useState(0)

	useEffect(() => {
		const timer = setInterval(() => setSeconds(seconds + 1), 1000)

		return () => clearInterval(timer)
	}, [seconds])

	if(seconds >= 60) {
		setSeconds(0)
		setMinutes(minutes + 1)
	}

	return (
		<div>
			{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
		</div>
	)
}

export default Timer
