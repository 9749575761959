import React, { Fragment, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Modal, Col, Button } from 'react-bootstrap'

import no_pickup from '../../assets/img/no_pickup.png'

export default function EndCallModal({ gigId, calledTargetId, calledTargetLeadActionId, onClose, onShowModal }) {
	const [talkedToPerson, setTalkedToPerson] = useState(null)

	const [updateTarget] = useMutation(UPDATE_TARGET, {
		onCompleted(data) {
			const pickedUp = talkedToPerson === 'YES' ? true : false

			onClose(pickedUp)
		}
	})

	// useEffect(() => {}, [ta])

	const handleSelectReason = (reason) => {
		if(reason === 'SUCCESSFUL') {
			setTalkedToPerson('YES')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						lead_action_id: calledTargetLeadActionId,
						was_called: true,
						picked_up: true,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'CALL_BACK') {
			setTalkedToPerson('NO')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						lead_action_id: calledTargetLeadActionId,
						was_called: true,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'INVALID_NUMBER') {
			setTalkedToPerson('NO')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						was_called: true,
						lead_action_id: calledTargetLeadActionId,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'NO_ANSWER') {
			setTalkedToPerson('NO')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						lead_action_id: calledTargetLeadActionId,
						was_called: true,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'BUSY_SIGNAL') {
			setTalkedToPerson('NO')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						lead_action_id: calledTargetLeadActionId,
						was_called: true,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'ENDED_BY_AMBASSADOR') {
			setTalkedToPerson('NO')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						was_called: true,
						lead_action_id: calledTargetLeadActionId,
						phone_exception_reason: reason,
					}
				}
			})
		}

		if(reason === 'VOICEMAIL') {
			setTalkedToPerson('NO')

			updateTarget({
				variables: {
					gigId,
					targetId: calledTargetId,
					target: {
						was_called: true,
						lead_action_id: calledTargetLeadActionId,
					}
				}
			})
		}
	}

	return (
		<Modal
			centered
			show={true}
			size='lg'
			onHide={() => {}}
		>
			<Modal.Body className='text-center p-5'>
				<h6 className='text-left pointer' onClick={() => setTalkedToPerson(null)}>back</h6>
				{talkedToPerson === 'YES' && <DidTalk onSelectReason={handleSelectReason} />}
				{talkedToPerson === 'NO' && <DidNotTalk onSelectReason={handleSelectReason} />}
				{!talkedToPerson &&
					<Fragment>
						<h3 className='fd-green'>Did you speak with a person?</h3>
						<div className='d-flex justify-content-around w-100 mt-5'>
							<div className='custom-red-btn pointer pl-5 pr-5' onClick={() => setTalkedToPerson('NO')}>No</div>
							{/*<div className='custom-green-btn pointer pl-5 pr-5' onClick={() => setTalkedToPerson('YES')}>Yes</div>*/}
							<div className='custom-green-btn pointer pl-5 pr-5' onClick={() => onShowModal(false)}>Yes</div>
						</div>
					</Fragment>
				}
			</Modal.Body>
		</Modal>
	)
}

function DidTalk({ onSelectReason }) {
	const SUCCESSFUL = 'SUCCESSFUL'
	const CALL_BACK = 'CALL_BACK'
	const INVALID_NUMBER = 'INVALID_NUMBER'
	const VOICEMAIL = 'VOICEMAIL'
	const ENDED_BY_AMBASSADOR = 'ENDED_BY_AMBASSADOR'

	return (
		<div className='d-flex flex-column'>
			<Col md={2} className='d-flex m-auto'>
				<img src={no_pickup} alt='' className='img-max' />
			</Col>
			<h4 className='text-danger mt-3 mb-4'>Tell us what happened</h4>
			<div className='d-flex flex-wrap justify-content-center'>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(SUCCESSFUL)}>Proceed to next step</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(CALL_BACK)}>I have to call back</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(INVALID_NUMBER)}>Invalid Number</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(VOICEMAIL)}>Voicemail</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(ENDED_BY_AMBASSADOR)}>I ended the call</div>
			</div>
		</div>
	)
}

function DidNotTalk({ onSelectReason }) {
	const INVALID_NUMBER = 'INVALID_NUMBER'
	const NO_ANSWER = 'NO_ANSWER'
	const BUSY_SIGNAL = 'BUSY_SIGNAL'
	const ENDED_BY_AMBASSADOR = 'ENDED_BY_AMBASSADOR'
	const VOICEMAIL = 'VOICEMAIL'

	return (
		<div className='d-flex flex-column'>
			<Col md={2} className='d-flex m-auto'>
				<img src={no_pickup} alt='' className='img-max' />
			</Col>
			<h4 className='text-danger mt-3 mb-4'>Tell us what happened</h4>
			<div className='d-flex flex-wrap justify-content-center'>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(INVALID_NUMBER)}>Invalid Number</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(NO_ANSWER)}>No Answer</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(BUSY_SIGNAL)}>Busy Signal</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(ENDED_BY_AMBASSADOR)}>I ended the call</div>
				<div className='custom-green-btn pl-5 pr-5 m-1 pointer' onClick={() => onSelectReason(VOICEMAIL)}>Voicemail</div>
			</div>
		</div>
	)
}

const UPDATE_TARGET = gql`
	mutation EditTarget($gigId: ID!, $targetId: ID!, $target: TargetInputs) {
		editTarget(gigId: $gigId, targetId: $targetId, target: $target) {
			target_id
			was_called
			start_time
			end_time
			duration
			picked_up
			left_voicemail
			contact_id
			lead_action_id
			phone_exception_reason
		}
	}
`
