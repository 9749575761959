export default function measureDistance(p1, p2) {
	const earthRadius = 6378137 // meters
	const dLat = rad(p2.lat - p1.lat)
	const dLng = rad(p2.lng - p1.lng)

	const a = Math.sin(dLat / 2) *
						Math.sin(dLat / 2) +
						Math.cos(rad(p1.lat)) *
						Math.cos(rad(p2.lat)) *
						Math.sin(dLng / 2) *
						Math.sin(dLng / 2)

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	const meters = earthRadius * c

	const miles = meters * 0.0006213712

	return miles.toFixed(1)
}

function rad(x) {
	return x * Math.PI / 180
}
