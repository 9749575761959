import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { find } from 'lodash'
import moment from 'moment-timezone'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import logo from '../../assets/img/fd_logo.png'
import profile_person from '../../assets/img/profile_person.png'

import '../../styles/Welcome.css'

export default function Welcome({ ambassador, gigs, onGigClick }) {
	const defaultPhoto = find(ambassador.profile_photos, img => img.default_photo)

	const { loading: loadingImg, error: imgError, data: imgUrl } = useQuery(GET_AMBASSADOR_IMG, {
		variables: {
			key: defaultPhoto ? defaultPhoto.photo_url : ambassador.profile_photos[0].photo_url
		}
	})
// console.log(gigs)

	return (
		<div className='welcome-container'>
			<div className='welcome-modal'>
				<img src={logo} alt='' />
				<div className='welcome-profile-img'>
					<img src={imgUrl ? imgUrl.getAwsUrl : profile_person} alt='' />
				</div>
				<div className='fd-green text-center'>
					<h3>Hi {ambassador.first_name},</h3>
					{gigs.length
						? <GigsList gigs={gigs} onGigClick={onGigClick} />
						: <div>
								<h6>You don't have a Phone Gig today.</h6>
								<div style={{ fontSize: '12px' }}>Please check Field Day mobile app for job opportunities.</div>
							</div>
					}
				</div>
			</div>
		</div>
	)
}

function GigsList({ gigs, onGigClick }) {
	const endOfToday = moment().endOf('day').valueOf()
console.log(endOfToday)
	return (
		<div>
			<h5>Select which gig to start</h5>
			<Row className='mb-3 border-bottom'>
				<Col md={7}><b>GIG</b></Col>
				<Col><b>DATE</b></Col>
			</Row>
			{gigs.map((gig, i) => (
				<Row
					key={gig.id}
					className={`border-bottom ${i === 0 ? 'pointer' : ''}`}
					onClick={i === 0 && gig.due_date_millis < endOfToday ? () => onGigClick(gig) : () => {}}
				>
					<Col className={i !== 0 && 'text-muted'} md={7}>{gig.title}</Col>
					{/*<Col className={i !== 0 && 'text-muted'}>{moment(Number(gig.due_date_millis)).format('MMM Do, h:mma')}</Col>*/}
					<Col className={i !== 0 && 'text-muted'}>
						{moment(Number(gig.due_date_millis)).tz(gig.time_zone).format('MMM Do, h:mma')} ({gig.time_zone_human})
					</Col>
				</Row>
			))}
		</div>
	)
}

const GET_AMBASSADOR_IMG = gql`
	query GetAwsUrl($key: String!) {
		getAwsUrl(key: $key)
	}
`
