import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

export default function Notes({ leadActionId, setNotesExist }) {
	const [notes, setNotes] = useState('')
	const [lastNote, setLastNote] = useState('')
	const [autoSaved, setAutoSaved] = useState(false)

	const [saveNotes, { data }] = useMutation(SAVE_NOTES, {
		onCompleted(data) {
			setAutoSaved(true)
		}
	})

	useEffect(() => {
		setAutoSaved(false)

		const timer = setTimeout(() => {

			if(lastNote !== notes) {
				if(notes) {
					setNotesExist(true)
				} else {
					setNotesExist(false)
				}

				saveNotes({
					variables: {
						id: leadActionId,
						leadAction: {
							notes
						}
					}
				})

				setLastNote(notes)
			}
		}, 1000)

		return () => clearTimeout(timer)

	}, [notes])

	return (
		<div>
			<div className='notes-header'>
				NOTES&nbsp;
				{autoSaved && <em style={{ fontSize: '12px' }}>(saved)</em>}
			</div>
			<textarea
				className='notes-content w-100'
				value={notes}
				onChange={(e) => setNotes(e.target.value)}
				style={{
					border: 'none',
					resize: 'none',
				}}
			/>
		</div>
	)
}

const SAVE_NOTES = gql`
	mutation EditLeadAction($id: ID!,$ leadAction: CreateLeadActionInputs) {
		editLeadAction(id: $id, leadAction: $leadAction) {
			id
		}
	}
`
