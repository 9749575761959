import React, { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { map } from 'lodash'

export default function WhatToSay({ gig }) {
	const [tab, setTab] = useState('bestPractices')
	const tabNameMapping = {
		bestPractices: 'Best Practices',
		brandDetails: 'Brand Details',
		script: 'Script'
	}
// console.log(gig)
	return (
		<div className='mt-4'>
			<Header onTabSelect={setTab} />
			{tab === 'bestPractices' && <Content title={'Best Practices'} content={gig && gig.best_practices} />}
			{tab === 'brandDetails' && <Content title={'Brand Details'} content={gig && gig.brand_details} />}
			{tab === 'script' && <Content title={'Script'} content={gig && gig.script} />}
		</div>
	)
}

function Header({ onTabSelect }) {
	return (
		<div>
			<Button
				variant='info'
				className='w-100 fake-btn'
				style={{ backgroundColor: '#39b2bf' }}
				onClick={null}
			>
				What to Say to Businesses
			</Button>
			<div className='d-flex justify-content-around border-bottom border-info bg-white fd-green pl-4 pr-4 pt-2 pb-2'>
				<div className='pointer' onClick={() => onTabSelect('bestPractices')}>Best Practices</div>
				<div className='pointer' onClick={() => onTabSelect('brandDetails')}>Brand Details</div>
				<div className='pointer' onClick={() => onTabSelect('script')}>Script</div>
			</div>
		</div>
	)
}

function Content({ title, content }) {
	const createMarkup = (htmlText) => {
		return { __html: htmlText }
	}

	return (
		<Fragment>
			<div className='fd-green w-100 pl-5 pt-3 bg-white'>{title}</div>
			<div className='what-to-say-content w-100'>
				<div dangerouslySetInnerHTML={createMarkup(content)}></div>
			</div>
			{/*<textarea
				disabled
				className='what-to-say-content w-100'
				value={content}
				style={{
					border: 'none',
					resize: 'none',
				}}
			/>*/}
		</Fragment>
	)
}
