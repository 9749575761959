import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import dialerIcon from '../../assets/img/dialer_icon.png'
import hangUpIcon from '../../assets/img/hangUp_icon.png'
import muteIcon from '../../assets/img/mute_icon.png'
import Timer from './Timer'

export default function CallBar({ onPhone, calledTargetLeadActionId, onKeypadClick, onMuteClick, muted, onHangUp, onFinishingDetail }) {
	const getText = () => {
		if(!onPhone && !calledTargetLeadActionId) {
			return <div>Call next business</div>
		}

		if(onPhone && !calledTargetLeadActionId) {
			return 'Calling..'
		}

		if(onPhone && calledTargetLeadActionId) {
			return (
				<div className='d-flex'>
					LIVE&nbsp;<Timer />&nbsp;Call should be 2-3 minutes
				</div>
			)
		}

		if(!onPhone && calledTargetLeadActionId) {
			return (
				<Fragment>
					Call ended. Finish filling out the contact form and your notes, and then click "Done"
					{/*<div
						className='custom-green-btn pl-5 pr-5 pointer'
						style={{ position: 'absolute', right: '5px' }}
						onClick={onFinishingDetail}
					>
						Done
					</div>*/}
				</Fragment>
			)
		}
	}

	return (
		<Row noGutters className='mt-3'>
			<Col md={onPhone ? 10 : 12}>
				<div className={`big-call-btn ${onPhone ? 'live-call-btn' : ''}`}>
					{getText()}
				</div>
			</Col>
			{onPhone &&
				<Col className='d-flex' style={{ height: '45px' }}>
					<Col>
						<img
							alt=''
							className='pointer'
							src={dialerIcon}
							style={{ maxHeight: '100%' }}
							onClick={onKeypadClick}
						/>
					</Col>
					<Col>
						<img
							alt=''
							className='pointer'
							src={muteIcon}
							style={{ maxHeight: '100%', border: `${muted ? '3px solid red' : ''}` }}
							onClick={onMuteClick}
						/>
					</Col>
					<Col>
						<img
							alt=''
							className='pointer'
							src={hangUpIcon}
							style={{ maxHeight: '100%' }}
							onClick={onHangUp}
						/>
					</Col>
				</Col>
			}
		</Row>
	)
}
