import React, { useEffect, useState } from 'react'
import createActivityDetector from 'activity-detector'

function useIdle(options) {
	const [isIdle, setIsidle] = useState(false)

	useEffect(() => {
		const activityDetector = createActivityDetector(options)

		activityDetector.on('idle', () => setIsidle(true))
		activityDetector.on('active', () => setIsidle(false))

		return () => activityDetector.stop()
	}, [])

	return isIdle
}

export default useIdle
