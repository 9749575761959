import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import logo from '../../assets/img/logo.png'

function Header({ isAuthenticated, setIsAuthenticated }) {
	const ambassadorId = localStorage.getItem('ambassadorId')
	const gigId = localStorage.getItem('gigId')

	const handleLogOut = () => {
		localStorage.removeItem('ambassadorId')
		localStorage.removeItem('gigId')

		setIsAuthenticated(false)
	}

	return (
		<Navbar collapseOnSelect bg='info'>
      <Container>
      	<Navbar.Brand>
      		<img
      			alt=''
      			src={logo}
      			width='30'
      			height='30'
      		/>
      		<div className='d-inline ml-2 mt-2 text-white font-weight-bold'>FIELD DAY</div>
      	</Navbar.Brand>
      	{isAuthenticated || ambassadorId
					? <Button onClick={handleLogOut} variant='dark'>Log Out</Button>
      		: <Redirect
							// push
							to={{
								pathname: '/login'
							}}
						/>
      	}
      </Container>
		</Navbar>
	)
}

export default Header
