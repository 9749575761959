import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Login from './containers/Login'
import Home from './containers/Home'
import PhoneGig from './containers/PhoneGig'

function Routes() {
  return (
    <Switch>
      <Route exact path='/'><Home /></Route>
      <Route path='/login'><Login /></Route>
      <Route path='/phoneGig'><PhoneGig /></Route>
      <Route>
        <h1 style={{ padding: '100px', textAlign: 'center' }}>Page Not Found!</h1>
      </Route>
    </Switch>
  )
}

export default Routes
