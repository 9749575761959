import React, { Fragment, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Modal, Col, Button } from 'react-bootstrap'
import { Rating } from 'semantic-ui-react'

import topIcon from '../../assets/img/modal_top_icon.png'

export default function RateModal({ onCompleteCall }) {
	const [rating, setRating] = useState(null)

	return (
		<Modal
			centered
			show={true}
			// size='lg'
			onHide={() => {}}
		>
			<img
				alt=''
				src={topIcon}
				style={{
					position: 'absolute',
					top: '-25px',
					left: '34%',
					maxWidth: '170px'
				}}
			/>
			<Modal.Body className='text-center p-5'>
				<h1 className='fd-green font-weight-bold mt-4 mb-5'>What was the quality of this lead?</h1>
				<Rating
					maxRating={5}
					defaultRating={0}
					icon='star'
					size='massive'
					onRate={(e, data) => setRating(data.rating)}
				/>
			</Modal.Body>
			<div
				className='custom-green-btn pl-5 pr-5 m-1 pointer'
				onClick={() => onCompleteCall(rating)}
				style={{
					position: 'absolute',
					bottom: '-15px',
					left: '34%',
					width: '170px'
				}}
			>
				Done
			</div>
		</Modal>
	)
}
